@import '../_breakpoints.scss';

@include lg {
	html,
	body,
	.App,
	.auth-layout-wrap,
	.wrap,
	.login {
		max-width: 100vw;
		max-height: 100vh;
	}

	body {
		.dashboard-layout-wrap .main-body-wrap .main-wrap {
			// background-color: red !important;
		}

		.new-transfer {
			width: unset;
		}

		.tablet-menu-toggle {
			display: grid !important;
			visibility: visible !important;
			position: fixed;
			z-index: 50000;
			display: grid;
			box-shadow: 0px 0px 0px 4px #dff6ed;
			left: 5.5rem;
			top: 6.5rem;
			width: 2.5rem;
			height: 2.5rem;
			place-items: center;
			background-color: var(--primary-accent-color-two);
			border-radius: 75%;
			cursor: pointer;
			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

			svg {
				transform: rotate(270deg);
				transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

				path {
					stroke: var(--primary-base-color);
				}
			}

			&.collapsed {
				left: 23rem;
				svg {
					transform: rotate(90deg);
					transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
				}
			}
		}

		.container-page-wrap .container-page .content {
			max-width: 50%;
		}
		.dashboard-layout-wrap .side-nav-wrap {
			width: 7rem;
			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

			&.tablet-collapsed {
				width: 20%;

				transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			}

			&:not(.tablet-collapsed) {
				.dashboard-sidemenu-wrap {
					.nav-list-box .toggle-nav-box .get-started .title {
						display: none;
					}

					.nav-list-box .list-box .nav-link .text {
						display: none;
					}

					.logo-text-box {
						svg g path:not(:first-child) {
							display: none;
						}
					}

					.toggle-nav-box {
						display: none;
					}

					.chat-support-box > * {
						display: none;
					}

					.chat-support-box {
						background-color: unset;
					}

					.toggle-hide-show .nav-link .text {
						display: none;
					}
					.name-email-drop-box {
						display: none;
					}
				}
			}
		}

		.dashboard-sidemenu-wrap {
			z-index: 4000;
			overflow: hidden;
			width: 100%;
		}
	}
	.preview-transfer {
		max-width: unset;
	}
	.dashboard-sidemenu-wrap
		.nav-list-box
		.parent-children-box
		.children-box-menu-clpsed {
		display: none;
	}

	.preview-payment-view {
		max-width: unset;
	}
}

// @include xl {
// 	body {
// 		// .new-transfer {
// 		// 	width: unset;
// 		// }

// 		.tablet-menu-toggle {
// 			display: grid !important;
// 			visibility: visible !important;
// 			position: fixed;
// 			z-index: 50000;
// 			display: grid;
// 			box-shadow: 0px 0px 0px 4px #dff6ed;
// 			left: 5.5rem;
// 			top: 4.5rem;
// 			width: 2.5rem;
// 			height: 2.5rem;
// 			place-items: center;
// 			background-color: var(--primary-accent-color-two);
// 			border-radius: 75%;
// 			cursor: pointer;
// 			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

// 			svg {
// 				transform: rotate(270deg);
// 				transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

// 				path {
// 					stroke: var(--primary-base-color);
// 				}
// 			}

// 			&.collapsed {
// 				left: 23.5rem;
// 				svg {
// 					transform: rotate(90deg);
// 					transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// 				}
// 			}
// 		}

// 		.dashboard-layout-wrap .side-nav-wrap {
// 			width: 7rem;
// 			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// 			z-index: 1000;

// 			&.tablet-collapsed {
// 				width: 25rem;
// 				transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// 			}

// 			&:not(.tablet-collapsed) {
// 				.dashboard-sidemenu-wrap {
// 					.nav-list-box .toggle-nav-box .get-started .title {
// 						display: none;
// 					}

// 					.nav-list-box .list-box .nav-link .text {
// 						display: none;
// 					}

// 					.logo-text-box {
// 						svg g path:not(:first-child) {
// 							display: none;
// 						}
// 					}

// 					.toggle-nav-box {
// 						display: none;
// 					}

// 					.chat-support-box > * {
// 						display: none;
// 					}

// 					.chat-support-box {
// 						background-color: unset;
// 					}

// 					.toggle-hide-show .nav-link .text {
// 						display: none;
// 					}
// 					.name-email-drop-box {
// 						display: none;
// 					}
// 				}
// 			}
// 		}

// 		.dashboard-sidemenu-wrap {
// 			z-index: 4000;
// 			overflow: hidden;
// 			width: 100%;
// 		}
// 	}
// 	.dashboard-layout-wrap .main-body-wrap {
// 		transition: all 0.3s ease-in-out;
// 		&.expanded {
// 			transition: all 0.3s ease-in-out;
// 			width: calc(100% - 7rem);
// 		}
// 	}
// }
