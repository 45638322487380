@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_extra_light_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_extra_light_italic-webfont.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_extra_light-webfont.woff2") format("woff2"), url("./fonts/br_firma_extra_light-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_light_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_light_italic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_light-webfont.woff2") format("woff2"), url("./fonts/br_firma_light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_medium_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_medium_italic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_medium-webfont.woff2") format("woff2"), url("./fonts/br_firma_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_regular_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_regular_italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_regular-webfont.woff2") format("woff2"), url("./fonts/br_firma_regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_semibold_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_semibold_italic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_semibold-webfont.woff2") format("woff2"), url("./fonts/br_firma_semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_thin_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_thin_italic-webfont.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_thin-webfont.woff2") format("woff2"), url("./fonts/br_firma_thin-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_black_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_black_italic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_black-webfont.woff2") format("woff2"), url("./fonts/br_firma_black-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_bold_italic-webfont.woff2") format("woff2"), url("./fonts/br_firma_bold_italic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "br_firma";
  src: url("./fonts/br_firma_bold-webfont.woff2") format("woff2"), url("./fonts/br_firma_bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
:root {
  --base-white: #ffffff;
  --base-black: #020202;
  --base-background-white: #f9f9f9;
  --base-background-black: #676767;
  --border-green: var(--primary-accent-color-two);
  --border-grey-dark: #676767;
  --border-grey-light: #cccccc;
  --border-light: #f7f8f7;
  --primary-green: var(--primary-accent-color-two);
  --primary-deep-green: #014345;
  --primary-blue: #476885;
  --primary-orange: #ea872d;
  --primary-purple: #755ae2;
  --primary-black: #020202;
  --primary-grey-shade: #e5e6e6;
  --primary-base-color: #014345;
  --primary-accent-color-one: rgba(1, 67, 69, 0.1);
  --primary-accent-color-two: rgba(1, 67, 69, 0.5);
  --primary-accent-color-three: rgba(1, 67, 69, 0.8);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "br_firma", sans-serif;
}

html {
  font-size: 55.5% !important;
  box-sizing: border-box;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 52% !important;
  }
}

body {
  font-size: 1.4rem;
  font-family: "br_firma", sans-serif;
  font-weight: 400;
  /* overflow-x: hidden !important; */
  position: relative;
  width: 100vw;
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: hidden;
  color: rgb(27, 27, 27);
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
body .form-group__deep-green-light .wrap .label-calendar .img-box .img {
  opacity: 0.4;
}
@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.grey-white-color {
  color: #676767;
}

div.css-1io7ftr-option {
  background-color: var(--primary-base-color);
}

.table .table-body .table-row td {
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important;
}

.table .table-body tr td,
.table .table-body tr .table-data,
.table .table-body .table-row td,
.table .table-body .table-row .table-data,
.table tbody tr td,
.table tbody tr .table-data,
.table tbody .table-row td,
.table tbody .table-row .table-data {
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important;
}

.collections-wrap .collections__content .content__main .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}

.raven__form-global {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 2.5rem;
  flex: 1;
  overflow-y: scroll;
}
.raven__form-global::-webkit-scrollbar {
  display: none;
}

.form {
  overflow: hidden;
  padding-bottom: 2.5rem;
}

.atlas-payment-container .form {
  overflow: hidden;
  padding-bottom: 2.5rem !important;
}

.transaction__table::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar {
  display: none;
}

.more-wrap {
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  display: flex;
  top: 4rem;
  width: -moz-max-content;
  width: max-content;
  right: 4rem;
  z-index: 111;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
.more-wrap.show-drop {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.more-wrap .more_item {
  display: flex;
  align-items: center;
  padding: 1.2rem;
  gap: 1rem;
  width: 100%;
}
.more-wrap .more_item:not(:last-child) {
  border-bottom: 0.4px solid #e5e6e6;
}
.more-wrap .more_item figure {
  display: grid;
  place-items: center;
  height: 2rem;
  width: 2rem;
}
.more-wrap .more_item figure svg {
  width: 100%;
  height: 100%;
}

.more-wrap-drop-reuse {
  animation: moveDownSettingDrop 0.5s ease-in-out backwards;
}

.cursor-pointer {
  cursor: pointer;
}

.global-search > *:active,
.input-group__search > *:active,
.top-bar__search > *:active {
  border: none !important;
  box-shadow: none !important;
  color: #b3b3b3;
}
.global-search > *:focus,
.input-group__search > *:focus,
.top-bar__search > *:focus {
  border: unset !important;
  box-shadow: none !important;
}
.global-search > *:hover,
.input-group__search > *:hover,
.top-bar__search > *:hover {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group,
.input-group__search .input-group,
.top-bar__search .input-group {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group:active,
.input-group__search .input-group:active,
.top-bar__search .input-group:active {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group:focus,
.input-group__search .input-group:focus,
.top-bar__search .input-group:focus {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group:hover,
.input-group__search .input-group:hover,
.top-bar__search .input-group:hover {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group .form-input:active,
.input-group__search .input-group .form-input:active,
.top-bar__search .input-group .form-input:active {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group .form-input:focus:valid,
.input-group__search .input-group .form-input:focus:valid,
.top-bar__search .input-group .form-input:focus:valid {
  border: unset !important;
  box-shadow: none !important;
}
.global-search .input-group .form-input:hover,
.input-group__search .input-group .form-input:hover,
.top-bar__search .input-group .form-input:hover {
  border: none !important;
  box-shadow: none !important;
}
.global-search .input-group .search-box .img-box,
.input-group__search .input-group .search-box .img-box,
.top-bar__search .input-group .search-box .img-box {
  opacity: 0.5;
}

.form-group__deep-green-light .input-group .seach-box .img-box {
  opacity: 0.6;
}

.top-bar__search {
  width: 30rem;
  overflow: hidden;
}

.transaction__table {
  border-radius: 12px;
  border: 0.6px solid var(--ravengreyshade-50, #e5e6e6);
}

.table .table-head tr th,
.table thead tr th {
  padding-top: 2.2rem !important;
  padding-bottom: 2.2rem !important;
}

.atlas-mobile-table-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.8rem;
  overflow-y: scroll;
  display: none;
}

.non-desktop {
  display: none !important;
}

body .smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__apply-filter-btn {
  z-index: 300;
  background-color: white;
}

.smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date__form-wrap.show {
  padding-bottom: 20px;
}

.action_bar_show {
  padding: 1rem 6.4rem !important;
}

@media only screen and (max-width: 42em) {
  .form-group .input-submit {
    height: 5rem !important;
  }
}
.form-group .flatpickr-input {
  height: 5rem !important;
}

body .modal-parent-wrap .raven-modal-content-wrap .content {
  padding-bottom: 2rem;
}

.table-data {
  font-weight: unset !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

body .bankbox-create-modal .raven-modal-content-wrap {
  width: 100rem;
  position: relative;
}
body .bankbox-create-modal .raven-modal-content-wrap .close-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 3000;
}
body .bankbox-create-modal .raven-modal-content-wrap .content {
  position: relative;
  padding: 0;
}

table .account_name {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
table .account_name span {
  background-color: #e9f5ff;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 7.5rem;
}
table .account_name span p {
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--primary-base-color);
}

.table__main .account_name--transfer {
  display: flex;
  align-items: center;
  gap: 1.8rem;
}
.table__main .account_name--transfer .go-to-merchant {
  margin-left: -0.5rem;
  cursor: pointer;
}
.table__main .account_name--transfer .go-to-merchant .img-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.table__main .account_name--transfer .go-to-merchant .img-box .img {
  width: 60%;
  height: 60%;
  -o-object-fit: contain;
     object-fit: contain;
}
.table__main .account_name--transfer .account_name__span:first-child {
  background-color: #e9f5ff;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 7.5rem;
}
.table__main .account_name--transfer .account_name__span:first-child p:first-child {
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--primary-base-color);
}

.transaction-status {
  display: grid;
  place-items: center;
  background-color: aliceblue;
  padding: 0.8rem 1.2rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.2rem;
}
.transaction-status p {
  font-weight: 600;
  font-size: 1.2rem;
}
.transaction-status.pending {
  background-color: #fff6ed;
}
.transaction-status.pending p {
  color: #ea872d;
}
.transaction-status.successful {
  background-color: #e8fff6;
}
.transaction-status.successful p {
  color: #1ace37;
}
.transaction-status.refunded {
  background: var(--raven-purple-shade-20, #f0eeff);
}
.transaction-status.refunded p {
  color: var(--ravenprimarypurple, #755ae2);
  text-transform: capitalize;
}
.transaction-status.failed {
  background-color: #fff5f5;
}
.transaction-status.failed p {
  color: #ff0f00;
}

.session-expired-wrap-modal .form {
  padding-bottom: unset !important;
}

.colour-pallete {
  box-shadow: unset !important;
}

.global-search-pagination-filter-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.global-search-pagination-filter-box > *:first-child {
  flex: 1;
  margin-bottom: unset;
}

.bankbox-create-modal-update-fee .raven-modal-content-wrap {
  width: -moz-max-content !important;
  width: max-content !important;
  height: -moz-max-content;
  height: max-content;
}
.bankbox-create-modal-update-fee .raven-modal-content-wrap .bankbox-modals {
  min-height: unset;
}
.bankbox-create-modal-update-fee .raven-modal-content-wrap .bankbox-modals__right--content {
  height: -moz-max-content;
  height: max-content;
  flex: unset;
}
.bankbox-create-modal-update-fee .raven-modal-content-wrap .bankbox-modals__right--content .price-content__selected-more-details-box .label-boxes {
  flex: 0 0 40%;
}

.form-group__deep-green-light .input-group .form-input {
  border: none !important;
}

.general-actionbar {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.general-actionbar-bottom {
  margin-bottom: 1.5rem;
}

.non-over-flow-y-div {
  overflow-y: unset !important;
}

.raven-btn,
.tool-tip-wrap__deep-green-light,
.tool-tip-wrap__deep-green-light::after, .form-group__deep-green-light .input-submit {
  background-color: var(--primary-base-color) !important;
}

.spin .spintula-arc-error-light {
  border-bottom-color: #ffffff !important;
}

.pagination__page-count {
  text-wrap: nowrap;
}

.loader-md {
  padding: 0.8rem 2rem !important;
}/*# sourceMappingURL=index.css.map */