.auth-layout-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}
.auth-layout-wrap .left-wrap {
  flex: 0 0 60%;
  background-image: url("../../assets/atlas-registration-bog-one.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
}
.auth-layout-wrap .left-wrap .text-social-link-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transform: translateY(15%) translateX(20%);
}
.auth-layout-wrap .left-wrap .text-social-link-box .big-text-box {
  display: flex;
  flex-direction: column;
}
.auth-layout-wrap .left-wrap .text-social-link-box .big-text-box .text {
  font-size: 3.6rem;
  font-weight: 700;
  color: var(--primary-base-color);
  line-height: 140%;
}
.auth-layout-wrap .left-wrap .text-social-link-box .big-text-box .text span {
  color: #7879f1;
}
.auth-layout-wrap .left-wrap .text-social-link-box .small-text-box {
  margin-top: 3rem;
}
.auth-layout-wrap .left-wrap .text-social-link-box .small-text-box .text {
  color: var(--primary-base-color);
  font-size: 1.35rem;
}
.auth-layout-wrap .left-wrap .text-social-link-box .round-icon-box {
  display: flex;
  gap: 2rem;
  padding-top: 1.5rem;
  border-top: 0.15rem solid #b9defe;
  align-self: flex-start;
  margin-top: 1rem;
}
.auth-layout-wrap .left-wrap .text-social-link-box .round-icon-box .img-box {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.auth-layout-wrap .left-wrap .text-social-link-box .round-icon-box .img-box:hover {
  transform: scale(1.05);
}
.auth-layout-wrap .left-wrap .text-social-link-box .round-icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.auth-layout-wrap .left-wrap-two {
  background-image: url("../../assets/atlas-registration-bog-two.png");
}
.auth-layout-wrap .left-wrap-two .text-social-link-box {
  transform: unset;
  margin-top: auto;
  margin-bottom: 10rem;
  margin-left: 10%;
}
.auth-layout-wrap .right-wrap {
  flex: 1;
  display: grid;
  place-items: center;
}
.auth-layout-wrap .right-wrap .wrap {
  width: 45rem;
  min-height: 75%;
}
.auth-layout-wrap .right-wrap-login {
  order: -1;
}

.auth-layout-wrap-three {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.auth-layout-wrap-three > * {
  position: relative;
  z-index: 3;
}
.auth-layout-wrap-three::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: url("../../assets/atlas-registration-bog-three.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f0f8ff;
}
.auth-layout-wrap-three::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #ffffff;
}
.auth-layout-wrap-three .wrap {
  width: 45rem;
  min-height: 60rem;
  margin-top: 7rem;
  align-self: flex-start;
}
.auth-layout-wrap-three .live_chat {
  position: absolute;
  right: 5rem;
  bottom: 5rem;
}

@media screen and (max-width: 29.9375em) {
  .auth-layout-wrap {
    flex-direction: column;
  }
  .auth-layout-wrap .left-wrap .text-social-link-box .big-text-box {
    display: none;
  }
  .auth-layout-wrap .left-wrap .text-social-link-box {
    display: none;
  }
  .auth-layout-wrap .left-wrap {
    flex: 0 0 30%;
    background-size: cover;
    background-image: url("../../assets/images/registeration-mobile-bg.svg");
  }
  body .register .register__atlas-logo,
  body .login .login__atlas-logo,
  body .forgot-password .forgot-password__logo {
    display: none;
  }
  .auth-layout-wrap .right-wrap {
    justify-content: center;
    place-items: start;
    margin-top: 4.4rem;
  }
  .register__live-chat,
  .auth-layout-wrap-three .live_chat,
  .login__live-chat,
  body .forgot-password .forgot-password__body-wrap .body-wrap__back-to-login {
    display: none;
  }
  .auth-login-mobile-layout {
    flex-direction: column-reverse;
  }
  .auth-layout-wrap-three::after {
    background-image: url("../../assets/images/forgot-password-mobile-bg.svg");
    background-size: cover;
    height: 30%;
  }
  body .forgot-password .forgot-password__body-wrap {
    margin-top: 44%;
  }
  body .forgot-password .forgot-password__body-wrap .body-wrap__main-content,
  body .forgot-password .new-password__body-wrap .body-wrap__main-content {
    border: none;
  }
  body .forgot-password .new-password__body-wrap .body-wrap__main-content {
    margin-top: 30%;
  }
}/*# sourceMappingURL=AuthOneLayoutWrap.css.map */