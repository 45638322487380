.bills-layout-index-wrap-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: calc(100vh - 8rem);
  overflow-y: scroll;
}
.bills-layout-index-wrap-box .bills_layout {
  padding: 0 !important;
}
.bills-layout-index-wrap-box .children-route-wrapper-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 1.2rem;
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  gap: 1.5rem;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  border-width: 0.15rem;
  min-height: 7rem;
  overflow-x: scroll;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .right-box-btn {
  margin-left: auto;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row {
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.5rem;
  background-color: var(--primary-base-color);
  border-radius: 2rem;
  transform: translateY(1.5rem);
  visibility: hidden;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row .name {
  transition: all 0.3s ease-in;
  text-wrap: nowrap;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row:hover .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row-active {
  background-color: var(--primary-accent-color-one);
  cursor: default;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row-active::after {
  visibility: visible;
}
.bills-layout-index-wrap-box .children-route-wrapper-box .route-path-wrap-box .item-row-active .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.bills-layout-index-wrap-box .children-route-wrapper-box .children-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}

.reuseable-wrapper-route-path-wrap-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  border-width: 0.15rem;
  min-height: 7rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1000;
}
.reuseable-wrapper-route-path-wrap-box .right-box-btn {
  margin-left: auto;
}
.reuseable-wrapper-route-path-wrap-box .item-row {
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
}
.reuseable-wrapper-route-path-wrap-box .item-row::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.5rem;
  background-color: var(--primary-base-color);
  border-radius: 2rem;
  transform: translateY(1.5rem);
  visibility: hidden;
}
.reuseable-wrapper-route-path-wrap-box .item-row .name {
  transition: all 0.3s ease-in;
  text-wrap: nowrap;
}
.reuseable-wrapper-route-path-wrap-box .item-row .name-children {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.reuseable-wrapper-route-path-wrap-box .item-row .name-children .img-box {
  width: 2rem;
  height: 2rem;
  margin-top: 0.4rem;
}
.reuseable-wrapper-route-path-wrap-box .item-row .name-children .img-box svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.reuseable-wrapper-route-path-wrap-box .item-row:hover .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.reuseable-wrapper-route-path-wrap-box .item-row .drop-box-wrap {
  position: absolute;
  top: 150%;
  left: 30%;
  min-width: 17rem;
  max-height: 25rem;
  border-radius: 0.8rem;
  border-width: 0.12rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.reuseable-wrapper-route-path-wrap-box .item-row .drop-box-wrap > *:last-child {
  border-bottom: unset;
}
.reuseable-wrapper-route-path-wrap-box .item-row .drop-box-wrap .child-name {
  padding: 1.5rem;
  border-width: 0.12rem;
}
.reuseable-wrapper-route-path-wrap-box .item-row-active {
  background-color: var(--primary-accent-color-one);
  cursor: default;
}
.reuseable-wrapper-route-path-wrap-box .item-row-active::after {
  visibility: visible;
}
.reuseable-wrapper-route-path-wrap-box .item-row-active .name {
  font-weight: 600;
  color: var(--primary-base-color);
}/*# sourceMappingURL=billsLayoutIndex.css.map */