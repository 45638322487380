.dispute-detail-modal-classname {
	// display: none !;
	.raven-modal-content-wrap {
		width: 52rem !important;
	}
}

.dispute-modal-content-wrap-index {
	display: flex;
	flex-direction: column;

	.title-select-wrap {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		grid-gap: 1.5rem;
		padding: 1rem 2rem;
		border-radius: 0.8rem;
		justify-items: flex-start;
		margin-bottom: 2rem;

		.title-select {
			padding: 1.5rem 1.5rem;
			border-radius: 0.8rem;
			cursor: pointer;

			span {
				font-size: 1.25rem;
			}
		}

		.title-select-active {
			cursor: not-allowed;
			span {
				font-weight: 700;
				color: var(--primary-base-color);
			}
		}
	}

	.content-to-show-wrap {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		// margin-top: 2rem;
		min-height: 35rem;
		max-height: 55rem;
		overflow-y: scroll;

		.empty_all_bb {
			width: 100%;
			height: 100%;
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// align-items: center;

			h6 {
				font-size: 2rem;
				font-style: normal;
				font-weight: 600;
				color: var(--primary-base-color);
			}

			p {
				color: var(--RAVEN-GREY, #676767);
				text-align: center;
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
			}
		}

		.form-content-wrap-index {
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			gap: 2rem;

			.badge-info-box {
				display: flex;
				align-items: center;
				gap: 1rem;
				// margin-bottom: 2rem;

				.bagde-box {
					padding: 0.8rem 1.5rem;
					border-radius: 2.5rem;

					// span {
					// 	// font-weight: 600;
					// }
				}

				.info-box {
					display: grid;
					place-items: center;
					border-radius: 50%;
					width: 2rem;
					height: 2rem;
					cursor: pointer;

					.img-box {
						width: 100%;
						height: 100%;
						cursor: pointer;

						.img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}

		.btn-box-wrap {
			margin-top: 2rem;
		}

		.value-label-wrap-index-box {
			display: flex;
			flex-direction: column;
			border-radius: 1.2rem;
			padding: 2rem 0rem 2rem 0rem;
			gap: 2rem;

			.label-value-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0rem 1.5rem;
				// padding-b: unset;

				.label {
					font-weight: 600;
				}

				.value {
					text-align: right;
					flex: 0 0 65%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					text-align: right;
				}

				.copy-wrap-index {
					display: flex;
					align-items: center;
					// justify-content: space-evenly;
					gap: 0.8rem;

					// .copy-box {
					// 	display: grid;
					// 	place-items: center;
					// 	border-radius: 50%;
					// 	width: 3rem;
					// 	height: 3rem;
					//   position: relative;

					// 	.img-box {
					// 		width: 100%;
					// 		height: 100%;
					// 		cursor: pointer;
					//     position: relative;

					// 		.img {
					// 			width: 100%;
					// 			height: 100%;
					// 			object-fit: contain;
					// 		}
					// 	}
					// }
				}
			}

			& > *:not(:last-child) {
				padding-bottom: 2rem;
				// margin-bottom: 1.5rem;
			}
		}
	}
}
