.dashboard-layout-headernav-wrap {
	display: flex;
	width: 100%;
	height: 100%;
	gap: 1.4rem;
	align-items: center;

	.back-text-icon-wrap-box {
		display: flex;
		align-items: center;
		gap: 0.7rem;
		width: max-content;
		cursor: pointer;

		&:hover {
			.img-wrap {
				transform: translateX(-0.12rem);
			}
		}

		.text {
			color: rgba(1, 67, 69, 1);
			font-size: 1.55rem;
			// font-weight: 600;
		}

		.img-wrap {
			transition: all 0.3s ease-in-out;
			.img-box {
				width: 2.5rem;
				height: 2.5rem;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.mobile-hamburger {
		display: none;
	}
	.search-box {
		margin-right: auto;
		// margin-left: auto;

		form {
			.form-group {
				.input-group {
					//   background-color: #ffffff;

					input {
						&:active {
							border: unset;
						}
					}
				}
			}
		}
	}

	.profile-avatar-box {
		.img-box {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;

			.img {
				object-fit: cover;
				width: 100%;
				border-radius: 50%;
				height: 100%;
			}
		}

		.avatar-box {
			width: 4.1rem;
			height: 4.1rem;
			border-radius: 50%;
			display: grid;
			place-items: center;
			//   background-color: #e8fff6;

			span {
				display: inline-block;
				font-weight: 700;
				font-size: 1.5rem;
				// color: var(--primary-base-color);
				text-transform: uppercase;
				letter-spacing: 0.1rem;
			}
		}
	}

	.theme-switch-box {
		display: flex;
		align-items: center;
		gap: 1rem;
		// background-color: #f7f8f7;
		padding: 0.6rem 1rem;
		border-radius: 1.2rem;

		.switch {
			// background-colo;
			cursor: pointer;
			padding: 0.5rem 1rem;
			border-radius: 0.8rem;
		}

		.switch-active {
			cursor: default;
			//   background-color: #ffffff;
			box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
				0px 48px 64px -36px rgba(51, 51, 51, 0.08);
		}
	}

	.mode-box {
		display: flex;
		align-items: center;
		padding: 0.9rem 1.5rem;
		border-radius: 0.8rem;
		border: 0.125rem solid #e5e6e6;
		cursor: pointer;
		min-width: 17rem;
		gap: 0.8rem;

		.color-box {
			width: 1.7rem;
			height: 1.7rem;
			border-radius: 50%;
			background-color: #ea872d;
		}

		.text {
			//   color: #1b1b1b;
			margin-right: auto;
			font-weight: 600;
			font-size: 1.35rem;
		}

		.icon-box {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			display: grid;
			place-items: center;
			//   background-color: #f7f8f7;

			.icon {
				width: 60%;
				height: 60%;
			}
		}
	}

	.api-documentation-box {
		display: flex;
		align-items: center;
		gap: 1.2rem;
		padding: 1rem 1.5rem;
		border-radius: 0.8rem;
		border: 0.125rem solid #e5e6e6;
		cursor: pointer;

		&:hover .img-box {
			transform: translateX(0.2rem) translateY(-0.2rem);
		}

		.text {
			font-weight: 600;
			font-size: 1.35rem;
			color: #755ae2;
		}

		.img-box {
			width: 1.2rem;
			height: 1.2rem;
			transition: all 0.3s ease-in-out;

			.img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}

.legacy_toast {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.6rem;

	button {
		border: none;
		background: none;
		box-shadow: none;
		border: 1px solid rgba(0, 0, 0, 0.237);
		padding: 0.6rem 2rem;
		border-radius: 0.8rem;
		cursor: pointer;
		color: #0000007e;

		font-size: 1.2rem;
	}
}

@keyframes moveDownSettingDrop {
	0% {
		transform: translateY(-5%);
		opacity: 0.6;
		// visibility: hidden;
	}
	100% {
		transform: translateY(0%);
		opacity: 1;
		// visibility: ;
	}
}
