.title-text-index-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap {
    gap: 1rem;
  }
}
.title-text-index-box-wrap .back-text-box {
  margin-right: auto;
  display: flex;
  align-items: center;
  flex: 1;
}
@media only screen and (max-width: 52em) {
  .title-text-index-box-wrap .back-text-box {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}
.title-text-index-box-wrap .back-text-box .back-box {
  margin-right: 1rem;
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--primary-base-color);
  cursor: pointer;
  transition: transform 0.3s;
}
@media only screen and (max-width: 42em) {
  .title-text-index-box-wrap .back-text-box .back-box {
    flex: 0 0 9%;
    width: unset;
    height: unset;
  }
}
.title-text-index-box-wrap .back-text-box .back-box:hover {
  transform: translateX(-0.1rem);
}
.title-text-index-box-wrap .back-text-box .back-box .img-box {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
  position: relative;
}
@media only screen and (max-width: 42em) {
  .title-text-index-box-wrap .back-text-box .back-box .img-box {
    width: 3.5rem;
    height: 3.5rem;
  }
}
.title-text-index-box-wrap .back-text-box .back-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 42em) {
  .title-text-index-box-wrap .back-text-box .back-box .img-box .img {
    width: 40%;
    height: 40%;
  }
}
.title-text-index-box-wrap .back-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
@media only screen and (max-width: 42em) {
  .title-text-index-box-wrap .back-text-box .text-box {
    gap: 1rem;
  }
}
.title-text-index-box-wrap .back-text-box .text-box .title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.05rem;
}
@media only screen and (max-width: 42em) {
  .title-text-index-box-wrap .back-text-box .text-box .title {
    font-size: 2rem;
    line-height: 1.5rem;
  }
}
.title-text-index-box-wrap .back-text-box .text-box .text {
  font-size: 1.4rem;
}
@media only screen and (max-width: 42em) {
  .title-text-index-box-wrap .back-text-box .text-box .text {
    font-size: 1.3rem;
  }
}
.title-text-index-box-wrap .title-text-box {
  display: flex;
  flex-direction: column;
}
.title-text-index-box-wrap .title-text-box .title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  line-height: 2.5rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .title-text-box .title {
    font-size: 2.2rem;
  }
}
.title-text-index-box-wrap .title-text-box .text {
  font-size: 1.35rem;
  text-align: center;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .title-text-box .text {
    text-align: left;
  }
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box {
    margin-left: auto;
  }
}
.title-text-index-box-wrap .children-box .btn-insight {
  border-color: var(--primary-purple);
  border-width: 0.1rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box .btn-insight {
    padding: 0.8rem 1rem !important;
  }
}
.title-text-index-box-wrap .children-box .btn-insight .img-box {
  width: 1.8rem;
  height: 1.8rem;
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box .btn-insight .img-box {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.title-text-index-box-wrap .children-box .btn-insight .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.title-text-index-box-wrap .children-box .btn-insight .text {
  color: var(--primary-purple);
}
@media only screen and (max-width: 48em) {
  .title-text-index-box-wrap .children-box .btn-insight .text {
    display: none;
  }
}/*# sourceMappingURL=TitleTextBox.css.map */