.change-request-status-modal-wrap {
	// min-height: 15rem;
	display: flex;
	flex-direction: column;

	.title {
		margin-bottom: 4rem;
		font-weight: 700;
		font-size: 2rem;
	}

	.status-select-box-wrap {
		display: flex;
		align-items: center;
		gap: 2rem;
		margin-bottom: 2rem;

		.status-item {
			padding: 1.2rem 1.5rem;
			border-radius: 3rem;
			width: max-content;
			cursor: pointer;

			span {
				font-size: 1.25rem;
				font-weight: 500;
			}

			// background-color: ;
		}

		.status-item-active {
			background-color: #e8fff6 !important;
			cursor: default;

			span {
				color: var(--primary-base-color);
			}
		}
	}
}
