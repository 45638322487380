.register {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;

	.back-stepbox-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1.1rem;
		// display: none;

		.img-back-wrap-boc {
			.img-box {
				width: 4rem;
				border-radius: 50%;
				height: 4rem;
				display: grid;
				place-items: center;
				background-color: var(--primary-base-color);
				cursor: pointer;

				.img {
					width: 50%;
					object-fit: contain;
					height: 50%;
				}
			}
		}
	}

	.register__atlas-logo {
		display: flex;
		align-items: center;
		gap: 1.5rem;
		margin-bottom: 5.6rem;

		figure {
			height: fit-content;
			// border-right: 1px solid #a0d2fe;
			// padding-right: 1.5rem;
		}

		p {
			font-weight: 900;
			font-size: 2rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: var(--primary-blue);
		}
	}
	.register__create-account {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 4rem;

		h5 {
			font-weight: 700;
			font-size: 2.4rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: #000000;
		}
		p {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: var(--border-grey-dark);
		}
	}
	.register__form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 2.8rem;
		width: 100%;

		.form__grouped {
			display: flex;
			gap: 2rem;
			width: 100%;
		}

		.form__action {
			margin-top: calc(5.6rem - 2.8rem);
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 2.4rem;

			.action__have-an-account {
				display: flex;
				gap: 1rem;
				align-items: center;

				p {
					font-weight: 400;
					font-size: 1.6rem;
					line-height: 160%;
					display: flex;
					align-items: center;
					color: var(--border-grey-dark);
				}

				span {
					font-weight: 700;
					font-size: 1.6rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					display: flex;
					align-items: center;
					color: var(--primary-purple);
					gap: 0.5rem;
					cursor: pointer;
					transition: transform 300ms ease-in-out;

					&:hover {
						transform: translateX(3px);
					}

					figure {
						display: grid;
						place-items: center;
					}
				}
			}
		}
	}

	.register__onboarding-step {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
		grid-gap: 1rem;

		p {
			// width: 50%;
			height: 0.6rem;
			background: #e9f5ff;
			border-radius: 1.5rem;
		}

		.active {
			background: var(--primary-base-color);
		}

		// p:first-child {
		// 	border-top-left-radius: 1rem;
		// 	border-bottom-left-radius: 1rem;
		// 	// background-color: var(--primary-base-color);
		// }
		// p:last-child {
		// 	border-top-right-radius: 1rem;
		// 	border-bottom-right-radius: 1rem;
		// 	background: #e9f5ff;

		// 	&.active {
		// 		background: var(--primary-base-color);
		// 	}
		// }
	}

	.register__feature-select {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		width: 100%;

		.feature-select__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.5rem 1.8rem;
			border-radius: 0.8rem;
			cursor: pointer;
			border: 1px solid var(--primary-grey-shade);

			&.active {
				border: 1.6px solid var(--primary-base-color);
				background: #f8fcff;

				p {
					font-weight: 600;
					font-size: 1.4rem;
					line-height: 140%;
					color: var(--primary-base-color);
				}
			}
		}

		p {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 140%;
			color: black;
		}
	}

	.register__business-details {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 0.6rem;
		margin-top: 2.8rem;
		margin-bottom: 4rem;

		h5 {
			font-weight: 700;
			font-size: 2.4rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: #000000;
		}

		p {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: var(--border-grey-dark);
		}
	}

	.register__feature-submit {
		margin-top: 4rem;
	}
}

.register__live-chat {
	position: absolute;
	bottom: 2rem;
	right: 8rem;
}

.form-modal-auth {
	// display: none;
	// padding: 0rem 2rem;
	display: flex;
	flex-direction: column;

	.pin-group {
		// width: 85%;
		.pin_field_group {
			width: 85%;
		}

		.black-white-color {
			color: #020202;
		}

		.resend-text {
			color: var(--primary-purple);
			margin-bottom: 0rem;
			align-self: flex-start;
			margin-top: 2rem;
			cursor: pointer;
			font-weight: 600;
			// padding-top: 2rem;
		}
	}

	.resend-text {
		color: var(--primary-purple);
		margin-bottom: 0rem;
		align-self: flex-end;
		cursor: pointer;
		font-weight: 600;
	}

	.text-box {
		display: flex;
		flex-direction: column;
		text-align: left;
		gap: 0.5rem;
		margin-bottom: 2rem;

		.small-title {
			color: #676767;
			font-size: 1.2rem;
			font-weight: 400;
		}

		.big-title {
			font-size: 1.6rem;
			color: #020202;
			font-weight: 700;

			// @media only screen and (max-width: 42em) {
			//   font-size: 1.5rem;
			//   display: none;
			// }
		}
	}
}

.auth-pin-modal-wrap {
	.raven-modal-content-wrap {
		width: 40rem;

		.button-wrap .form {
			padding: 0;
		}

		.form-modal-auth .pin-group .pin_field_group {
			width: 100%;
			padding: 0 0.2rem;
		}
	}
}
