@media screen and (min-width: 768px) and (max-width: 74.9375em) {
  html,
  body,
  .App,
  .auth-layout-wrap,
  .wrap,
  .login {
    max-width: 100vw;
    max-height: 100vh;
  }
  body .new-transfer {
    width: unset;
  }
  body .tablet-menu-toggle {
    display: grid !important;
    visibility: visible !important;
    position: fixed;
    z-index: 50000;
    display: grid;
    box-shadow: 0px 0px 0px 4px #dff6ed;
    left: 5.5rem;
    top: 6.5rem;
    width: 2.5rem;
    height: 2.5rem;
    place-items: center;
    background-color: var(--primary-accent-color-two);
    border-radius: 75%;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  body .tablet-menu-toggle svg {
    transform: rotate(270deg);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  body .tablet-menu-toggle svg path {
    stroke: var(--primary-base-color);
  }
  body .tablet-menu-toggle.collapsed {
    left: 23rem;
  }
  body .tablet-menu-toggle.collapsed svg {
    transform: rotate(90deg);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  body .container-page-wrap .container-page .content {
    max-width: 50%;
  }
  body .dashboard-layout-wrap .side-nav-wrap {
    width: 7rem;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  body .dashboard-layout-wrap .side-nav-wrap.tablet-collapsed {
    width: 20%;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .nav-list-box .toggle-nav-box .get-started .title {
    display: none;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .nav-list-box .list-box .nav-link .text {
    display: none;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .logo-text-box svg g path:not(:first-child) {
    display: none;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .toggle-nav-box {
    display: none;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .chat-support-box > * {
    display: none;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .chat-support-box {
    background-color: unset;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .toggle-hide-show .nav-link .text {
    display: none;
  }
  body .dashboard-layout-wrap .side-nav-wrap:not(.tablet-collapsed) .dashboard-sidemenu-wrap .name-email-drop-box {
    display: none;
  }
  body .dashboard-sidemenu-wrap {
    z-index: 4000;
    overflow: hidden;
    width: 100%;
  }
  .preview-transfer {
    max-width: unset;
  }
  .dashboard-sidemenu-wrap .nav-list-box .parent-children-box .children-box-menu-clpsed {
    display: none;
  }
  .preview-payment-view {
    max-width: unset;
  }
}/*# sourceMappingURL=index.css.map */