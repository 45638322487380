.change-request-status-modal-wrap {
  display: flex;
  flex-direction: column;
}
.change-request-status-modal-wrap .title {
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 2rem;
}
.change-request-status-modal-wrap .status-select-box-wrap {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.change-request-status-modal-wrap .status-select-box-wrap .status-item {
  padding: 1.2rem 1.5rem;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.change-request-status-modal-wrap .status-select-box-wrap .status-item span {
  font-size: 1.25rem;
  font-weight: 500;
}
.change-request-status-modal-wrap .status-select-box-wrap .status-item-active {
  background-color: #e8fff6 !important;
  cursor: default;
}
.change-request-status-modal-wrap .status-select-box-wrap .status-item-active span {
  color: var(--primary-base-color);
}/*# sourceMappingURL=ChangeRequestStatusModal.css.map */