.general-action-modal .raven-modal-content-wrap {
  width: 42.4rem;
}
.general-action-modal .raven-modal-content-wrap .content {
  padding: 0;
}
.general-action-modal .dispute-modal {
  padding: 0;
}
.general-action-modal .dispute-modal .no-shadow {
  box-shadow: none;
}
.general-action-modal .dispute-modal .bl-modal__content {
  margin-bottom: 0;
}
.general-action-modal .dispute-modal .bl-modal__content .details-modal-wrap__content {
  margin-bottom: 0;
}
.general-action-modal .dispute-modal .bl-modal__action-btn-contain {
  background: unset;
}
.general-action-modal.action-big-modal .raven-modal-content-wrap {
  width: 80rem;
}
.general-action-modal.action-big-modal .bl-modal__action-btn-contain {
  justify-content: flex-end;
}
.general-action-modal.action-big-modal .raven-modal-content-wrap {
  width: 100rem;
}
.general-action-modal.action-big-modal .bl-modal__action-btn-contain {
  justify-content: flex-end;
}
.general-action-modal.action-normal-modal .raven-modal-content-wrap {
  width: 47rem;
}
.general-action-modal .bl-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.general-action-modal .bl-modal__content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 4.7rem;
  padding: 0 2rem;
}
.general-action-modal .bl-modal__title {
  color: var(--BaseBlack-BaseBlack, #1b1b1b);
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}
.general-action-modal .bl-modal__body {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
.general-action-modal .bl-modal__action-btn-contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--Grey-shade-10, #f7f8f7);
  padding: 2.3rem 2rem;
}
.general-action-modal .bl-modal__action-btn-contain .cancel-btn {
  border-radius: 1rem;
  border: 1px solid var(--RAVEN-black-500, #8b8b8b);
  display: flex;
  width: 18rem;
  height: 5.4rem;
  padding: 1.6rem 2.4rem;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  background-color: transparent;
  font-weight: 600;
  color: var(--RAVEN-black-500, #8b8b8b);
}
.general-action-modal .bl-modal__action-btn-contain .action-btn {
  border-radius: 1rem;
  display: flex;
  width: 18rem;
  font-size: 1.6rem;
  height: 5.4rem;
  padding: 1.6rem 2.4rem;
  justify-content: center;
  font-weight: 600;
  align-items: center;
}

.close--button-space .bl-modal__action-btn-contain {
  display: none;
}

.very-big-modal .bl-modal__content {
  max-height: 65vh;
  margin-bottom: 0rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
}

.inline-details-wrapper__xay {
  width: 100%;
  padding-top: 1rem;
}
.inline-details-wrapper__xay .inline-trx-details-main-wrapper {
  padding: 0;
}

.very-big-modal .inline-trx-details-main-wrapper .details-modal-wrap__content .content__main .main__account-details {
  max-height: -moz-max-content;
  max-height: max-content;
}

.inline-copy {
  display: flex;
  position: absolute;
  gap: 0.5rem;
  border-radius: 72px;
  width: -moz-max-content;
  width: max-content;
  background: var(--Grey-shade-10, #f7f8f7);
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.2rem;
  top: 2rem;
  right: 2rem;
}
.inline-copy p {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  color: #000;
}

.new-class-name .content {
  padding-bottom: 0rem !important;
}

.new-class-name button.raven-btn.btn-md.btn-deep-green-light.text-white-light.action-btn.undefined.undefined.loader-md {
  width: 25%;
}/*# sourceMappingURL=index.css.map */