.ragna-dropdown {
	.pb-dropdown__portal {
		left: -129px;
	}
}

.compliance-results {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	gap: 2rem;

	&__image-preview {
		display: flex;
		height: 34.6rem;
		width: 40%;
		flex-direction: column;
		align-items: center;
		border-radius: 12px;
		justify-content: end;
		background: var(--RAVEN-black-50, #f7f7f7);

		.image-preview__pagination {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1.2rem;
			margin-bottom: 2.4rem;
			span {
				display: flex;
				padding: 0.8rem 1.2rem;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				border-radius: 72px;
				background: #fff;

				/* Raven/Shadow LG */
				box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
					0px 24px 48px -8px rgba(51, 51, 51, 0.04);
				p {
					color: #000;
					text-align: center;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 500;
				}
			}
			figure {
				border-radius: 72px;
				opacity: 1;
				background: #fff;

				/* Raven/Shadow LG */
				box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
					0px 24px 48px -8px rgba(51, 51, 51, 0.04);
				display: flex;
				padding: 0.8rem;
				align-items: center;
				gap: 0.4rem;
			}
			figure.disabled {
				opacity: 0.25;
			}
		}
	}

	&__main {
		width: 60%;
	}
}

.padding-b .modal-parent-wrap .raven-modal-content-wrap .content {
	padding-bottom: 0rem !important;
}

.item-row {
	padding: 1.2rem 1.6rem;
	border-radius: 0.8rem;
	position: relative;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0%;
		width: 100%;
		height: 0.5rem;
		background-color: var(--primary-base-color);
		border-radius: 2rem;
		transform: translateY(1.5rem);
		visibility: hidden;
	}

	.name {
		transition: all 0.3s ease-in;
	}

	&:hover {
		.name {
			font-weight: 600;
			color: var(--primary-base-color);
		}
	}
}

.item-row-active {
	background-color: var(--primary-accent-color-one);
	cursor: default;

	&::after {
		visibility: visible;
	}

	.name {
		font-weight: 600;
		color: var(--primary-base-color);
	}
}

.tabs {
	display: flex;
	margin-top: 1.5rem;
}
