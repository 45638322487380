.accept-invite-page-index-wrap-box {
	width: 100vw;
	height: 100vh;
	display: grid;
	place-items: center;
	background-color: rgb(249, 250, 251);
	overflow-y: scroll;
}
@media only screen and (max-width: 48em) {
	.accept-invite-page-index-wrap-box {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		padding-top: 2rem;
	}
}
.accept-invite-page-index-wrap-box::-webkit-scrollbar {
	display: none;
}
.accept-invite-page-index-wrap-box .middle-content {
	width: 45rem;
	display: flex;
	flex-direction: column;
}
@media only screen and (max-width: 48em) {
	.accept-invite-page-index-wrap-box .middle-content {
		width: 95%;
	}
}
.accept-invite-page-index-wrap-box .middle-content .img-logo-box {
	width: 13rem;
	height: 5.5rem;
	align-self: center;
	margin-bottom: 2rem;
}
.accept-invite-page-index-wrap-box .middle-content .img-logo-box .img-box {
	width: 100%;
	height: 100%;
}
.accept-invite-page-index-wrap-box .middle-content .img-logo-box .img-box .img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}
.accept-invite-page-index-wrap-box .middle-content .content-wrap {
	min-height: 55rem;
	background-color: #ffffff;
	border-radius: 1.2rem;
	box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.accept-invite-page-index-wrap-box .middle-content .content-wrap .form-content {
	flex: 1;
	padding: 3rem;
	padding-bottom: unset;
	display: flex;
	flex-direction: column;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.content-wrap
	.form-content
	.form-title {
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 3.3rem;
	margin-bottom: 0.75rem;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.content-wrap
	.form-content
	.form-text {
	font-size: 1.55rem;
	color: rgb(139, 139, 139);
	line-height: 2rem;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.content-wrap
	.form-content
	.badge-two-role-mail-box {
	margin: 2rem 0rem;
	display: flex;
	align-items: center;
	gap: 1.5rem;
	flex-wrap: wrap;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.content-wrap
	.form-content
	.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-top: 1.5rem;
	flex: 1;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.content-wrap
	.form-content
	.form
	.check-drop-box
	.bottom-box
	.check-text-box
	.check-box-active {
	background-color: var(--primary-base-color);
}
.accept-invite-page-index-wrap-box
	.middle-content
	.content-wrap
	.form-content
	.form
	.btn {
	margin-top: auto;
}
.accept-invite-page-index-wrap-box .middle-content .powered-by-box {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	align-self: center;
	margin-top: 2rem;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.powered-by-box
	.icon-box
	.img-box {
	width: 2rem;
	height: 2rem;
}
.accept-invite-page-index-wrap-box
	.middle-content
	.powered-by-box
	.icon-box
	.img-box
	.img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}
.accept-invite-page-index-wrap-box .middle-content .powered-by-box .text {
	font-size: 1.3rem;
	font-weight: 600;
	color: var(--primary-base-color);
} /*# sourceMappingURL=AcceptInvitePage.css.map */
