.export-modal {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;

	&__title {
		display: flex;
		flex-direction: column;

		h5 {
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			line-height: 160%; /* 32px */
		}

		p {
			color: var(--raven-base-bg-dark, #676767);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 19.6px */
		}
	}

	&__export-as {
		display: flex;
		flex-direction: column;
		margin-top: 2.5rem;
	}

	&__export-as--selector-wrap {
		display: flex;
		gap: 1.6rem;
		margin-bottom: 2.8rem;

		.selector-wrap__item {
			display: flex;
			height: 6rem;
			padding: 0.8rem 1.2rem;
			align-items: center;
			gap: 12px;
			flex: 1 0 0;
			border-radius: 1.2rem;
			border: 1px solid var(--raven-black-100, #eee);
			background: var(--base-white, #fff);
			margin-top: 1.2rem;
			cursor: pointer;

			&.selected {
				border: 2px solid
					var(--primary-darkgreen-100, var(--primary-base-color));
				background: var(--raven-black-50, #f7f7f7);
			}
		}
	}

	&__days-selector {
		display: flex;
		flex-direction: column;

		.days-selector__item-wrap {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 1.2rem;
			padding: 2rem 0;

			.days-selector__item {
				display: flex;
				padding: 0.8rem 1.2rem;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 72px;
				cursor: pointer;
				background: var(--raven-black-50, #f7f7f7);

				&.selected {
					border-radius: 72px;
					border: 1.4px solid
						var(--primary-darkgreen-100, var(--primary-base-color));
					background: var(--raven-black-100, #eee);

					figure svg path {
						stroke: var(--primary-base-color);
					}

					figure {
						border-radius: 50rem;
						width: min-content;
						height: min-content;
						display: grid;
						place-items: center;
						overflow: hidden;
					}

					p {
						color: var(--primary-darkgreen-100, var(--primary-base-color));
						text-align: center;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 600;
						line-height: 140%; /* 16.8px */
					}
				}

				p {
					color: var(--raven-black-500, #8b8b8b);
					text-align: center;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 16.8px */
				}
			}
		}
	}

	&__custom-date {
		display: flex;
		flex-direction: row;
		gap: 2.4rem;
		align-items: center;
		margin-bottom: 4.9rem;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out;

		&.show {
			max-height: 400rem;
			transition: max-height 0.3s ease-in-out;
		}
	}
}
