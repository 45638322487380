.role-and-permission-wrap-box-index {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.role-and-permission-wrap-box-index .role-list-box {
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 3rem;
}
.role-and-permission-wrap-box-index .role-list-box .role-item {
  border-radius: 1.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  cursor: pointer;
}
.role-and-permission-wrap-box-index .role-list-box .role-item:hover .users-arrow-box .arrow-box {
  transform: translateX(0.3rem);
}
.role-and-permission-wrap-box-index .role-list-box .role-item .middle-icon {
  margin: auto;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .middle-icon .img-box {
  width: 10rem;
  height: 10rem;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .middle-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .arrow-box {
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .arrow-box .img-box {
  width: 65%;
  height: 65%;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .arrow-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .avatar-users-box {
  display: flex;
  align-items: center;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .avatar-users-box > *:not(:first-child) {
  margin-left: -1.5rem;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .avatar-users-box .avatar-box {
  width: 5rem;
  height: 5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border-width: 0.4rem;
  border-color: #ffffff;
  box-shadow: 0.1rem 0.1rem 4rem 0.2rem rgba(0, 0, 0, 0.07);
}
.role-and-permission-wrap-box-index .role-list-box .role-item .users-arrow-box .avatar-users-box .avatar-box span {
  display: inline-block;
  font-weight: 600;
  font-size: 1.35rem;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .name {
  font-size: 2.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.role-and-permission-wrap-box-index .role-list-box .role-item .des {
  font-size: 1.35rem;
  line-height: 2rem;
  margin-bottom: auto;
}
.role-and-permission-wrap-box-index .role-list-box-two {
  grid-template-columns: repeat(auto-fit, minmax(25rem, 33rem));
}

.single-role-and-permission-details-index-wrap {
  flex: 1;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  overflow-y: scroll;
}
.single-role-and-permission-details-index-wrap .role-title {
  font-size: 3.2rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}
.single-role-and-permission-details-index-wrap .description-box {
  width: 55rem;
}
.single-role-and-permission-details-index-wrap .description-box .text {
  font-size: 1.65rem;
  line-height: 2.1rem;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  border-width: 0.15rem;
  min-height: 7rem;
  margin-top: 2rem;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .right-box-btn {
  margin-left: auto;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row {
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.5rem;
  background-color: var(--primary-base-color);
  border-radius: 2rem;
  transform: translateY(1.5rem);
  visibility: hidden;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row .name {
  transition: all 0.3s ease-in;
  text-wrap: nowrap;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row:hover .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row-active {
  background-color: var(--primary-accent-color-one);
  cursor: default;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row-active::after {
  visibility: visible;
}
.single-role-and-permission-details-index-wrap .route-path-wrap-box .item-row-active .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.single-role-and-permission-details-index-wrap .permission-single-role-box {
  margin-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  margin-top: 2rem;
  gap: 1rem;
  padding: 0rem 1rem;
  border-width: 0.15rem;
  border-radius: 1.2rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .left-box {
  flex: 0 0 40%;
  border-width: 0.2rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .left-box .module-list-box {
  border-radius: 1.2rem;
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .left-box .module-list-box .name {
  font-size: 1.45rem;
  text-transform: capitalize;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .left-box .module-list-box .item-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .left-box .module-list-box .item-row .toggle-name-box {
  transform: scale(0.8);
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .right-box {
  margin-top: 1.5rem;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-left: 2rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .right-box .title-permissions-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .right-box .title-permissions-box .module-title {
  font-weight: 500;
  font-size: 1.8rem;
  text-transform: capitalize;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .right-box .title-permissions-box .permission-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .right-box .title-permissions-box .permission-box .permission-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.8rem;
  padding: 1.5rem;
  border-width: 0.15rem;
}
.single-role-and-permission-details-index-wrap .permission-single-role-box .left-right-box-wrap .right-box .title-permissions-box .permission-box .permission-row .permission-row-label {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap {
  flex: 1;
  margin-top: 3rem;
  overflow-y: scroll;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  align-items: flex-start;
  gap: 3.5rem;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box {
  height: 28rem;
  border-radius: 1.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box .user-icon {
  width: 70%;
  flex: 1;
  margin-bottom: -2.5rem;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box .user-icon .img-box {
  width: 100%;
  height: 100%;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box .user-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box .name-email-box {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.8rem;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box .name-email-box .name {
  font-weight: 600;
  font-size: 1.8rem;
  text-transform: capitalize;
  line-height: 2.5rem;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner .user-box .name-email-box .email {
  font-size: 1.25rem;
}
.single-role-and-permission-details-index-wrap .all-users-box-wrap .all-users-box-wrap-inner {
  grid-template-columns: repeat(auto-fit, minmax(25rem, 33rem));
}/*# sourceMappingURL=RolesPermissionIndex.css.map */