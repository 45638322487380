.dashboard-sidemenu-wrap {
	width: 100%;
	height: 100%;
	// background-color: red;
	display: flex;
	flex-direction: column;
	padding: 2rem 0rem;
	padding-left: 2rem;

	.logo-text-box {
		// padding: 0rem 2rem;
		// align-self: center;
		display: flex;
		align-items: center;

		.text {
			//   color: var(--primary-accent-color-two);
			font-weight: 600;
			font-size: 1.35rem;
		}

		.logo-box {
			margin-right: 1.2rem;
			padding-right: 1.2rem;
			height: min-content;

			.img-box {
				width: 10rem;
				height: min-content;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.name-email-drop-box {
		margin-top: 3rem;
		display: flex;
		justify-content: space-between;
		padding: 1.2rem 1.7rem;
		border: 0.1rem solid rgba(255, 255, 255, .5);
		border-radius: 0.8rem;
		width: calc(100% - 2rem);
		align-items: center;

		.icon-box {
			background: rgba(255, 255, 255, 0.12);
			width: 2.3rem;
			height: 2.3rem;
			border-radius: 50%;
			display: grid;
			place-items: center;

			.icon {
				color: var(--primary-accent-color-two);
				width: 70%;
				width: 70%;
			}
		}

		.name-email-box {
			display: flex;
			flex-direction: column;
			gap: 0.3rem;
			.name {
				color: #ffffff;
				font-size: 1.3rem;
				font-weight: 700;
			}

			.email {
				color: #cccccc;
				font-size: 1.2rem;
			}
		}
	}

	.nav-list-box {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-top: 4rem;
		gap: 1.5rem;
		// background-color: red;
		padding-bottom: 3rem;
		margin-bottom: 2rem;
		overflow-y: scroll;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			display: none;
		}

		.toggle-nav-box {
			.get-started {
				cursor: pointer;
				margin-bottom: 1rem;
				display: flex;
				align-items: center;
				padding-right: 2rem;
				color: #ffffff;

				.title {
					margin-right: auto;
					text-transform: uppercase;
					font-size: 1.1rem;
					font-weight: 700;
				}
			}
		}

		.list-box {
			display: flex;
			flex-direction: column;

			.nav-link {
				display: flex;
				align-items: center;
				gap: 1rem;
				padding: 1rem 0rem 1.5rem 0rem;
				cursor: pointer;
				position: relative;

				&:hover {
					.text {
						color: #ffffff;
					}
				}

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					height: 100%;
					width: 0.5rem;
					background-color: #ffffff;
					transform: translateY(-50%);
					visibility: hidden;
				}

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					height: 100%;
					width: 100%;
					background: linear-gradient(
						269.91deg,
						rgba(255, 255, 255, 0.1) 22.13%,
						rgba(255, 255, 255, 0) 96.37%
					);
					transform: translateY(-50%);
					visibility: hidden;
				}

				& > * {
					position: relative;
					z-index: 3;
				}

				.text {
					color: #cccccc;
					font-size: 1.2rem;
					transition: all 0.3s ease-in-out;
				}

				.img-wrap {
					// display: none;
					// width: ;
					.children-spacing {
						padding-left: 3.5rem;
					}

					.img-box {
						width: 3.5rem;
						height: 3.5rem;
						display: grid;
						place-items: center;
						background-color: rgba(255, 255, 255, 0.1);
						border-radius: 50%;

						.img {
							width: 60%;
							height: 60%;
							object-fit: contain;
						}
					}
					.bankbox-icon {
						// width: 1.9rem;
						// height: 1.9rem;
						// margin-left: 0.5rem;

						.img {
							width: 70%;
							height: 70%;
						}
					}
				}
			}

			.nav-link-active {
				cursor: default;
				.text {
					color: #ffffff;
					font-weight: 700;
				}

				&::after,
				&::before {
					visibility: visible;
				}

				.img-box {
					background-color: #020202 !important;
				}
			}
		}

		.parent-children-box {
			.nav-link-drop {
				padding-right: 2rem;

				.text {
					margin-right: auto;
				}

				.icon {
					color: #cccccc;
				}
			}

			.children-box {
				// transform: translateX(3rem);
				max-height: 0;
				overflow-x: hidden;
				overflow-y: hidden;
				transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				width: 100%;

				.img-box {
					margin-left: 3rem;
				}
			}

			.children-box-show {
				margin-bottom: 0rem;
				max-height: 30rem;
				transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
			}
		}
	}

	.toggle-hide-show {
		max-height: 0;
		overflow-x: hidden;
		overflow-y: hidden;
		transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		width: 100%;
		margin-bottom: 1.5rem;

		.nav-link {
			display: flex;
			align-items: center;
			gap: 1rem;
			padding: 1rem 0rem;
			cursor: pointer;
			position: relative;

			&:hover {
				.text {
					color: #ffffff;
				}
			}

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				height: 100%;
				width: 0.5rem;
				background-color: #ffffff;
				transform: translateY(-50%);
				visibility: hidden;
			}

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				height: 100%;
				width: 100%;
				background: linear-gradient(
					269.91deg,
					rgba(255, 255, 255, 0.1) 22.13%,
					rgba(255, 255, 255, 0) 96.37%
				);
				transform: translateY(-50%);
				visibility: hidden;
			}

			& > * {
				position: relative;
				z-index: 3;
			}

			.text {
				color: #cccccc;
				font-size: 1.2rem;
				transition: all 0.3s ease-in-out;
			}

			.img-wrap {
				.img-box {
					width: 2.5rem;
					height: 2.5rem;

					.img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}

		.nav-link-active {
			cursor: default;
			.text {
				color: #ffffff;
				font-weight: 700;
			}

			&::after,
			&::before {
				visibility: visible;
			}
		}
	}

	.toggle-show {
		margin-bottom: 0rem;
		max-height: 15rem;
		transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
	}

	.chat-support-box {
		margin-top: auto;
		width: calc(100% - 2rem);
		min-height: 14rem;
		border-radius: 1.2rem;
		background: rgba(255, 255, 255, 0.08);
		padding: 1rem;
		display: flex;
		align-items: flex-end;
		background-image: url('../../../../assets/help-chat-mascot.png');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;

		& > * {
			width: 100%;
		}
	}
}
