.bankbox-overview {
	display: flex;
	border-radius: 16px;
	height: 100%;
	width: 100%;
	background: linear-gradient(
			181deg,
			rgba(0, 0, 0, 0.01) 1.19%,
			rgba(0, 0, 0, 0.01) 27.63%,
			rgba(0, 0, 0, 0) 66.17%,
			rgba(0, 0, 0, 0) 110.43%
		),
		linear-gradient(180deg, #f4f5f4 0%, rgba(244, 245, 244, 0) 100%);

	&__left {
		width: 45%;
		// flex: 1;
	}

	&__right {
		width: 55%;
		flex: 1;
		padding: 0 25rem 0 4.8rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&--title {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			h6 {
				color: var(--themedarkmode, #000);
				font-size: 3.2rem;
				font-style: normal;
				font-weight: 700;
				line-height: 140%; /* 44.8px */
			}

			p {
				color: var(--RAVEN-GREY, #676767);
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
			}
		}

		&--points {
			display: flex;
			margin-top: 6rem;
			flex-direction: column;
			gap: 2.4rem;

			.main {
				display: flex;
				align-items: center;
				gap: 2rem;

				.bullet {
					width: 10px;
					height: 10px;

					p {
						background: var(
							--primary-green-100,
							var(--primary-accent-color-two)
						);
						width: 8px;
						height: 8px;
						border-radius: 1px;
					}
				}
				.point {
					color: var(--base-black-base-bg-dark, #676767);
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 400;
					line-height: 160%; /* 22.4px */
				}
			}
		}

		&--activate-btn {
			margin-top: 4rem;
		}
	}

	&__left {
		background-image: url('../../../../assets/images/bankbox-featured-image.png');
		background-size: 75%;
		background-repeat: no-repeat;
		background-position: bottom right;
	}

	&__right {
		border-radius: var(--Count, 0px);
		background: var(--themelightmode, #fff);
		border-top-right-radius: 16px;
	}
}
