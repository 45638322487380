.basic-details-settings {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	flex: 1;
	gap: 3.2rem;
	overflow-y: auto;

	&__generate-eod {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		border-radius: 12px;
		border: 1px solid var(--RAVEN-black-100, #eee);
		padding: 0.8rem 1.6rem;

		p {
			color: var(--RAVEN-black-700, #515151);
			// font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	.grouped__input {
		display: flex;
		margin-top: 2rem;
		position: relative;

		.input__copy-icon {
			// opacity: 0.5;
			z-index: 200;
			position: absolute;
			top: 3.5rem;
			right: 1rem;
			// width: 2.3rem;
			// height: 2.3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			place-items: center;

			figure {
				display: grid;
				place-items: center;
				width: 2.5rem;
				cursor: pointer;
				height: 2.5rem;
			}
		}
	}

	&__app-name {
		display: flex;
		gap: 2.4rem;
		flex-direction: column;
		display: flex;
		padding: 2.2rem 1.6rem 1.6rem 1.6rem;
		border-radius: 12px;
		border: 1px solid var(--raven-black-100, #eee);

		&--avatar {
			flex-direction: row;
			display: flex;
			align-items: center;
			gap: 2rem;
			padding-bottom: 2rem;
			border-radius: var(--Count, 0px);
			border-bottom: 1px solid var(--raven-black-100, #eee);
			position: relative;

			&:hover .edit-icon-box {
				visibility: visible;
			}

			.edit-icon-box {
				position: absolute;
				bottom: 1.5rem;
				left: 9rem;
				width: 2.1rem;
				height: 2.1rem;
				visibility: hidden;
				transition: all 0.3s ease-in-out;

				.img-box {
					width: 100%;
					height: 100%;

					.img {
						object-fit: contain;
						width: 100%;
						height: 100%;
					}
				}
			}

			p {
				color: var(--RAVEN-GREY, #676767);
				font-size: 1.6rem;
				font-style: normal;
				width: 45%;
				font-weight: 400;
			}

			img {
				width: 10rem;
				object-fit: cover;
				height: 10rem;
				border-radius: 72px;
			}
		}
		&--input-group {
			display: flex;
			align-items: end;
			gap: 2rem;

			.input-group {
				&__color-swatch {
					display: flex;
					gap: 0.6rem;
					height: 75%;
					padding: 0.6rem;
					// margin-bottom: 0.3rem;
					border-radius: 12px;
					align-items: center;
					border: 0.5px solid var(--raven-black-200, #e3e3e3);
					position: relative;

					.swatch-drop {
						top: 5rem;
						right: 0;
					}

					&--color-preview {
						width: 11.3rem;
						height: 100%;
						border-radius: 8px;
						background: var(
							--primary-green-100,
							var(--primary-accent-color-two)
						);
						align-items: center;
						display: flex;
						justify-content: center;

						flex: 1;

						p {
							color: rgba(255, 255, 255, 0.32);
							font-size: 1.2rem;
							font-style: normal;
							font-weight: 700;
							line-height: 140%; /* 16.8px */
						}
					}
					&--dropdown {
						display: flex;
						width: 3.8rem;
						height: 4.2rem;
						padding: 1rem 0.8rem;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						flex-shrink: 0;

						border-radius: 8px;
						background: var(--raven-black-50, #f7f7f7);
					}
				}
			}
		}
	}
	&__prices {
		display: flex;
		flex-direction: column;
		padding: 2.2rem 1.6rem 1.6rem 1.6rem;
		border-radius: 12px;
		border: 1px solid var(--raven-black-100, #eee);

		.table-pickup {
			margin: 2rem 0rem;
		}

		.title-button-box {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			.title-box {
				display: flex;
				flex-direction: column;

				.basic-details-settings__prices--title {
					margin-bottom: 0.6rem !important;
				}
			}

			.edit-text {
				font-weight: 600;
				font-size: 1.55rem;
				color: var(--primary-base-color);
				cursor: pointer;
			}
		}

		&--actionbar {
			padding-right: 2rem !important;
			padding-left: 2rem !important;
		}

		&--title {
			color: var(--themedarkmode, #000);
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 1.6rem;
		}

		&--input-group {
			display: flex;
			gap: 2rem;
			width: 100%;
			margin-top: 0.8rem;

			&.incomplete {
				margin-top: 3.6rem !important;
			}
			.inputs {
				display: flex;
				flex-direction: column;
				flex: 1 1 0;
				gap: 2.4rem;
			}
		}
	}

	.wrap {
		width: 100%;
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 2rem;
	}
}

.colour-pallete {
	display: flex;
	width: min-content;
	flex-direction: column;
	gap: 2rem;
	background-color: white;
	padding: 1.6rem;
	border-radius: 12px;
	box-shadow: 0px -3px 48px -8px #f7f8f7, 0px 4px 23px 12px #f7f8f7;
}

.settings {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	&__webhook {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		figure {
			width: max-content;
			display: flex;
			align-items: center;
			gap: 0.4rem;
			color: var(--primary-purple-100, #755ae2);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 600;

			cursor: pointer;

			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}

	.settings__title {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.title__main {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;

			h5 {
				font-weight: 700;
				font-size: 2rem;
				line-height: 140%;
				// color: #000000;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 160%;
				color: #676767;
			}
		}

		.title__swap-btn {
			display: flex;
			align-items: center;
			padding: 1rem 2.3rem;
			gap: 1rem;
			cursor: pointer;
			background: #ffffff;
			box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
				0px 48px 64px -36px rgba(51, 51, 51, 0.08);
			border-radius: 10px;
			transition: transform 0.2s linear;

			&:hover {
				transform: translateY(-3px);
				figure {
					animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);

					@keyframes rotate {
						0% {
							transform: rotate(0deg);
						}
						50% {
							transform: rotate(100deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
				}
			}

			p {
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 140%;
				display: flex;
				align-items: center;
				color: var(--primary-base-color);
			}

			figure {
				display: grid;
				place-items: center;
				width: 2rem;
				height: 2rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.settings__sidebar {
		display: flex;
		align-items: flex-start;
		padding: 2rem;
		background: #ffffff;
		border-radius: 1.8rem;
		margin-top: 3.2rem;
		width: 100%;
		gap: 2.4rem;
		flex: 1;
		max-height: 90%;

		.sidebar__selector {
			background: #f9f9f9;
			border-radius: 1.2rem;
			display: flex;
			flex-direction: column;
			height: 100%;
			align-items: center;
			width: 20%;
			gap: 2rem;
			padding: 2rem;

			.selector__item {
				display: flex;
				flex-direction: row;
				align-items: center;
				// width: 13.8rem;
				width: 100%;
				padding: 1.6rem 2.4rem;
				gap: 0.8rem;
				cursor: pointer;

				box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
					0px 48px 64px -36px rgba(51, 51, 51, 0.08);
				border-radius: 10px;

				p {
					font-weight: 400;
					font-size: 1.4rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					color: #8b8b8b;
				}

				svg {
					width: 2rem;
					height: 2rem;
					display: grid;
					place-items: center;
				}

				&.active {
					background: #ffffff;
					p {
						color: var(--raven-primary-dark-green, var(--primary-base-color));
						font-weight: 700;
					}
				}
			}
		}

		.settings__main-wrap {
			background: #ffffff;
			// border-bottom: 1px solid #e3e3e3;
			display: flex;
			flex-direction: column;
			width: 85%;
			height: 100%;
			border-radius: 1.2rem;
			padding: 2rem;
			border: 1px solid var(--ravengreyshade-50, #e5e6e6);

			.main-wrap__title {
				display: flex;
				padding-bottom: 2rem;
				width: 100%;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;

				.title__left {
					h5 {
						color: var(--raven-base-background-dark, #020202);
						font-size: 2rem;
						font-weight: 600;
					}

					p {
						color: var(--raven-base-bg-dark, #676767);
						font-size: 1.2rem;
						line-height: 140%;
					}
				}

				.title__button {
					display: flex;
					align-items: center;
					gap: 0.8rem;

					svg {
						path:first-child {
							fill: var(--primary-accent-color-two);
						}
					}
				}
			}

			.history__content {
				display: flex;
				width: 100%;
				flex: 1;
				background: #ffffff;
				border-radius: 1.6rem;
				padding: 2rem;
				overflow-y: scroll;
				flex-direction: column;

				&::-webkit-scrollbar {
					display: none;
				}

				.content__top-bar {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;

					.top-bar__filter-export {
						display: flex;
						gap: 1.6rem;
						align-items: center;
						z-index: 1;
						position: relative;

						.filter-export__export-btn,
						.filter-export__filter-btn {
							flex-direction: row;
							justify-content: center;
							align-items: center;
							display: flex;
							padding: 1.6rem 2.4rem;
							gap: 0.8em;
							cursor: pointer;
							height: 5rem;
							background: #f7f8f7;
							border-radius: 1rem;
							z-index: 100;

							p {
								font-weight: 600;
								font-size: 1.4rem;
								line-height: 140%;
								display: flex;
								align-items: center;
								color: #676767;
							}

							figure {
								width: fit-content;
								display: grid;
								place-items: center;

								svg {
									width: 2rem;
									height: 2rem;
								}
							}
						}

						.filter-export__drop-down {
							position: absolute;
							background: #ffffff;
							width: max-content;
							height: max-content;
							box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
								0px 48px 64px -36px rgba(51, 51, 51, 0.08);
							border-radius: 1.6rem;
							box-shadow: 0;
							top: 110%;
							right: 55%;
							padding: 2rem;
							transform-origin: top right;
							transform: scale(0);
							transition: transform 0.3s ease-in-out;
							z-index: 100;

							&--open {
								transform: scale(1);
							}

							.drop-down__switch {
								display: flex;
								width: 100%;
								cursor: pointer;
								justify-content: flex-start;
								gap: 1.2rem;
								border-bottom: 0.8px solid #e5e6e6;
								span {
									gap: 0.4rem;
									padding-bottom: 1.6rem;

									p {
										font-weight: 400;
										font-size: 1.4rem;
										display: grid;
										place-items: center;
										padding: 1.2rem 1.6rem;
										border-radius: 8px;
										color: #1b1b1b;
									}

									&.active {
										border-bottom: 4px solid var(--primary-base-color);
										p {
											color: var(--primary-base-color);
											font-weight: 600;
											background: #e9f5ff;
										}
									}
								}
							}

							.drop-down__custom-date-range {
								display: flex;
								flex-direction: column;
								margin-top: 2.4rem;

								h5 {
									font-weight: 600;
									font-size: 1.4rem;
									color: var(--primary-base-color);
								}

								.custom-date-range__grouped-form {
									display: flex;
									align-items: center;
									gap: 2rem;
									margin-top: 1.2rem;
								}

								.drop-down__action-btns {
									display: flex;
									align-items: center;
									justify-content: center;
									gap: 2rem;
									width: 100%;
									margin-top: 5.2rem;

									padding: 2.3rem 0rem 0.3rem 0rem;
									border-top: 0.7px solid #e5e6e6;

									button {
										width: 50%;

										&:first-child {
											background: #e9f5ff;
											color: var(--primary-base-color);
										}
									}
								}
							}
						}

						.filter-export__drop-down-closer {
							position: fixed;
							width: 100vw;
							height: 100vh;
							top: 0;
							right: 0;

							display: none;
							visibility: none;

							&--opened {
								display: unset;
								visibility: unset;
							}
						}
					}
				}

				.content__main {
					display: flex;
					width: 100%;
					overflow-y: scroll;
					flex: 1;
					flex-direction: column;
					margin-top: 2rem;

					&::-webkit-scrollbar {
						display: none;
					}

					&--top-bar {
						display: flex;
						align-items: center;
						width: 100%;
						justify-content: space-between;

						.top-bar__title {
							display: flex;
							align-items: center;
							gap: 2.4rem;

							h5 {
								font-weight: 600;
								font-size: 16px;
								line-height: 140%;
								/* or 22px */

								display: flex;
								align-items: center;

								/* RAVEN/Base Black */

								color: #1b1b1b;
							}

							p {
								display: none;
								&.active {
									display: grid;
									place-items: center;
									padding: 0.8rem 1.2rem;
									background: #f7f8f7;
									border-radius: 7.2rem;
									font-weight: 600;
									font-size: 1.2rem;
									line-height: 140%;
									text-align: center;
									color: var(--primary-base-color);
								}
							}
						}
					}

					.main__active-filters {
						width: 100%;
						justify-content: space-between;
						align-items: center;
						overflow: hidden;
						max-height: 0;
						display: flex;
						transition: max-height 300ms ease-in-out;

						&--show {
							display: flex;
							overflow: unset;
							max-height: 30rem;
							transition: max-height 300ms ease-in-out;
						}

						.active-filters__item {
							background: #e9f5ff;
							border-radius: 7.2rem;
							display: flex;
							padding: 0.55rem 0.7rem;
							align-items: center;
							gap: 1rem;
							margin-top: 1rem;
							margin-bottom: 2rem;

							figure {
								width: 1.75rem;
								height: 1.75rem;
								display: grid;
								place-items: center;
								cursor: pointer;

								svg {
									width: 100%;
									height: 100%;
								}
							}

							p {
								color: #476885;
								display: flex;
								align-items: center;
								gap: 0.6rem;
							}
						}

						.active-filters__clear-item {
							background: #fff5f5;
							border-radius: 72px;
							border-radius: 7.2rem;
							display: flex;
							padding: 0.55rem 0.7rem;
							align-items: center;
							cursor: pointer;

							p {
								font-weight: 600;
								font-size: 1.2rem;
								color: #ff0f00;
							}
						}
					}

					.main__wrap {
						display: flex;
						margin-top: 2rem;
						width: 100%;
						height: 100%;
						// background-color: red;
						overflow: hidden;

						.transaction__table {
							width: 100%;
							// background-color: yellow;
							height: 100%;
							border-radius: 1.2rem;
							overflow: auto;

							&::-webkit-scrollbar {
								display: none;
							}

							.table__main {
								border-radius: 1.2rem;
								overflow: auto;

								.main__direction {
									display: flex;
									align-items: center;
									gap: 0.8rem;

									figure {
										display: grid;
										place-items: center;
										width: 2.75rem;
										height: 2.75rem;

										svg {
											width: 100%;
											height: 100%;
										}
									}
								}
							}
						}
					}
				}
			}

			.settings__teams {
				.content__main {
					.main__wrap {
						.transaction__table {
							.table__main {
								.account_name {
									display: flex;
									align-items: center;
									gap: 0.6rem;
									span {
										background-color: #e9f5ff;
										display: grid;
										place-items: center;
										width: 4rem;
										height: 4rem;
										border-radius: 7.5rem;

										p {
											font-weight: 600;
											font-size: 1.2rem;
											display: flex;
											align-items: center;
											text-align: center;
											color: var(--primary-base-color);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.add-location-modal-index-wrap {
	min-height: 35rem;
	display: flex;
	flex-direction: column;

	.title-text-box {
		.title {
			font-size: 2rem;
			font-weight: 600;
			color: #020202;
		}

		.text {
			font-size: 1.5rem;
		}
	}

	.form-box {
		margin-top: 2rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.select-available-days-box {
			display: flex;
			flex-direction: column;
			margin-bottom: 1rem;
			.label {
				margin-bottom: 0.4rem;
				font-size: 1.3rem;
				color: #020202;
				text-transform: capitalize;
			}

			.select-days {
				margin-top: 2rem;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 1.5rem;

				.item-box {
					width: max-content;
					border-radius: 3rem;
					padding: 0.6rem 2rem;
					background-color: #e8fff6;
					cursor: pointer;
					transition: transform 0.3s ease-in-out;

					&:hover {
						transform: translateY(-0.1rem);
					}

					.item {
						color: var(--primary-accent-color-two);
						font-size: 1.2rem;
						font-weight: 500;
					}
				}

				.item-box-active {
					background-color: var(--primary-base-color);

					&:hover {
						transform: unset;
					}

					.item {
						color: #ffffff;
					}
				}
			}
		}

		.two-group-box {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 2rem;
		}
	}
}

.settings-general-wrap-box-wrapper {
	flex: 1;
	// background-color: red;
	display: flex;
	gap: 3rem;

	.left-content {
		flex: 0 0 25rem;
		border-radius: 1.2rem;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		gap: 1.5rem;

		.item-row {
			padding: 1.2rem 1.6rem;
			border-radius: 0.8rem;
			position: relative;
			cursor: pointer;
			flex-wrap: wrap;
			&::after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0%;
				width: 100%;
				height: 0.5rem;
				background-color: var(--primary-base-color);
				border-radius: 2rem;
				transform: translateY(1.5rem);
				visibility: hidden;
			}

			.name {
				transition: all 0.3s ease-in;
				text-wrap: nowrap;
			}

			&:hover {
				.name {
					font-weight: 600;
					color: var(--primary-base-color);
				}
			}
		}

		.item-row-active {
			background-color: var(--primary-accent-color-one);
			cursor: default;

			&::after {
				// visibility: visible;
			}

			.name {
				font-weight: 600;
				color: var(--primary-base-color);
			}
		}
	}

	.right-content {
		flex: 1;
		padding-top: 2rem;
		display: flex;
		flex-direction: column;
	}
}
