.basic-details-settings {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  gap: 3.2rem;
  overflow-y: auto;
}
.basic-details-settings__generate-eod {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--RAVEN-black-100, #eee);
  padding: 0.8rem 1.6rem;
}
.basic-details-settings__generate-eod p {
  color: var(--RAVEN-black-700, #515151);
  font-style: normal;
  font-weight: 400;
}
.basic-details-settings .grouped__input {
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.basic-details-settings .grouped__input .input__copy-icon {
  z-index: 200;
  position: absolute;
  top: 3.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}
.basic-details-settings .grouped__input .input__copy-icon figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: 2.5rem;
}
.basic-details-settings__app-name {
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  display: flex;
  padding: 2.2rem 1.6rem 1.6rem 1.6rem;
  border-radius: 12px;
  border: 1px solid var(--raven-black-100, #eee);
}
.basic-details-settings__app-name--avatar {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  border-radius: var(--Count, 0px);
  border-bottom: 1px solid var(--raven-black-100, #eee);
  position: relative;
}
.basic-details-settings__app-name--avatar:hover .edit-icon-box {
  visibility: visible;
}
.basic-details-settings__app-name--avatar .edit-icon-box {
  position: absolute;
  bottom: 1.5rem;
  left: 9rem;
  width: 2.1rem;
  height: 2.1rem;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.basic-details-settings__app-name--avatar .edit-icon-box .img-box {
  width: 100%;
  height: 100%;
}
.basic-details-settings__app-name--avatar .edit-icon-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.basic-details-settings__app-name--avatar p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.6rem;
  font-style: normal;
  width: 45%;
  font-weight: 400;
}
.basic-details-settings__app-name--avatar img {
  width: 10rem;
  -o-object-fit: cover;
     object-fit: cover;
  height: 10rem;
  border-radius: 72px;
}
.basic-details-settings__app-name--input-group {
  display: flex;
  align-items: end;
  gap: 2rem;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch {
  display: flex;
  gap: 0.6rem;
  height: 75%;
  padding: 0.6rem;
  border-radius: 12px;
  align-items: center;
  border: 0.5px solid var(--raven-black-200, #e3e3e3);
  position: relative;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch .swatch-drop {
  top: 5rem;
  right: 0;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch--color-preview {
  width: 11.3rem;
  height: 100%;
  border-radius: 8px;
  background: var(--primary-green-100, var(--primary-accent-color-two));
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch--color-preview p {
  color: rgba(255, 255, 255, 0.32);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
}
.basic-details-settings__app-name--input-group .input-group__color-swatch--dropdown {
  display: flex;
  width: 3.8rem;
  height: 4.2rem;
  padding: 1rem 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--raven-black-50, #f7f7f7);
}
.basic-details-settings__prices {
  display: flex;
  flex-direction: column;
  padding: 2.2rem 1.6rem 1.6rem 1.6rem;
  border-radius: 12px;
  border: 1px solid var(--raven-black-100, #eee);
}
.basic-details-settings__prices .table-pickup {
  margin: 2rem 0rem;
}
.basic-details-settings__prices .title-button-box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.basic-details-settings__prices .title-button-box .title-box {
  display: flex;
  flex-direction: column;
}
.basic-details-settings__prices .title-button-box .title-box .basic-details-settings__prices--title {
  margin-bottom: 0.6rem !important;
}
.basic-details-settings__prices .title-button-box .edit-text {
  font-weight: 600;
  font-size: 1.55rem;
  color: var(--primary-base-color);
  cursor: pointer;
}
.basic-details-settings__prices--actionbar {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
.basic-details-settings__prices--title {
  color: var(--themedarkmode, #000);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1.6rem;
}
.basic-details-settings__prices--input-group {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin-top: 0.8rem;
}
.basic-details-settings__prices--input-group.incomplete {
  margin-top: 3.6rem !important;
}
.basic-details-settings__prices--input-group .inputs {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 2.4rem;
}
.basic-details-settings .wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2rem;
}

.colour-pallete {
  display: flex;
  width: -moz-min-content;
  width: min-content;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  padding: 1.6rem;
  border-radius: 12px;
  box-shadow: 0px -3px 48px -8px #f7f8f7, 0px 4px 23px 12px #f7f8f7;
}

.settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.settings__webhook {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.settings__webhook figure {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: var(--primary-purple-100, #755ae2);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.settings__webhook figure svg {
  width: 1.5rem;
  height: 1.5rem;
}
.settings .settings__title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.settings .settings__title .title__main {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.settings .settings__title .title__main h5 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 140%;
}
.settings .settings__title .title__main p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  color: #676767;
}
.settings .settings__title .title__swap-btn {
  display: flex;
  align-items: center;
  padding: 1rem 2.3rem;
  gap: 1rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  transition: transform 0.2s linear;
}
.settings .settings__title .title__swap-btn:hover {
  transform: translateY(-3px);
}
.settings .settings__title .title__swap-btn:hover figure {
  animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.settings .settings__title .title__swap-btn p {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--primary-base-color);
}
.settings .settings__title .title__swap-btn figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.settings .settings__title .title__swap-btn figure svg {
  width: 100%;
  height: 100%;
}
.settings .settings__sidebar {
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  background: #ffffff;
  border-radius: 1.8rem;
  margin-top: 3.2rem;
  width: 100%;
  gap: 2.4rem;
  flex: 1;
  max-height: 90%;
}
.settings .settings__sidebar .sidebar__selector {
  background: #f9f9f9;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 20%;
  gap: 2rem;
  padding: 2rem;
}
.settings .settings__sidebar .sidebar__selector .selector__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.6rem 2.4rem;
  gap: 0.8rem;
  cursor: pointer;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
}
.settings .settings__sidebar .sidebar__selector .selector__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #8b8b8b;
}
.settings .settings__sidebar .sidebar__selector .selector__item svg {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.settings .settings__sidebar .sidebar__selector .selector__item.active {
  background: #ffffff;
}
.settings .settings__sidebar .sidebar__selector .selector__item.active p {
  color: var(--raven-primary-dark-green, var(--primary-base-color));
  font-weight: 700;
}
.settings .settings__sidebar .settings__main-wrap {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 100%;
  border-radius: 1.2rem;
  padding: 2rem;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title {
  display: flex;
  padding-bottom: 2rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__left h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 2rem;
  font-weight: 600;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__left p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__button {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__button svg path:first-child {
  fill: var(--primary-accent-color-two);
}
.settings .settings__sidebar .settings__main-wrap .history__content {
  display: flex;
  width: 100%;
  flex: 1;
  background: #ffffff;
  border-radius: 1.6rem;
  padding: 2rem;
  overflow-y: scroll;
  flex-direction: column;
}
.settings .settings__sidebar .settings__main-wrap .history__content::-webkit-scrollbar {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  z-index: 1;
  position: relative;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1.6rem 2.4rem;
  gap: 0.8em;
  cursor: pointer;
  height: 5rem;
  background: #f7f8f7;
  border-radius: 1rem;
  z-index: 100;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn p,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn figure,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn figure svg,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure svg {
  width: 2rem;
  height: 2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down {
  position: absolute;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 1.6rem;
  box-shadow: 0;
  top: 110%;
  right: 55%;
  padding: 2rem;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down--open {
  transform: scale(1);
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1.2rem;
  border-bottom: 0.8px solid #e5e6e6;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span {
  gap: 0.4rem;
  padding-bottom: 1.6rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: grid;
  place-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  color: #1b1b1b;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active {
  border-bottom: 4px solid var(--primary-base-color);
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active p {
  color: var(--primary-base-color);
  font-weight: 600;
  background: #e9f5ff;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--primary-base-color);
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: var(--primary-base-color);
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main::-webkit-scrollbar {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* RAVEN/Base Black */
  color: #1b1b1b;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title p {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title p.active {
  display: grid;
  place-items: center;
  padding: 0.8rem 1.2rem;
  background: #f7f8f7;
  border-radius: 7.2rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
  color: var(--primary-base-color);
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item figure {
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.settings .settings__sidebar .settings__main-wrap .settings__teams .content__main .main__wrap .transaction__table .table__main .account_name {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.settings .settings__sidebar .settings__main-wrap .settings__teams .content__main .main__wrap .transaction__table .table__main .account_name span {
  background-color: #e9f5ff;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 7.5rem;
}
.settings .settings__sidebar .settings__main-wrap .settings__teams .content__main .main__wrap .transaction__table .table__main .account_name span p {
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--primary-base-color);
}

.add-location-modal-index-wrap {
  min-height: 35rem;
  display: flex;
  flex-direction: column;
}
.add-location-modal-index-wrap .title-text-box .title {
  font-size: 2rem;
  font-weight: 600;
  color: #020202;
}
.add-location-modal-index-wrap .title-text-box .text {
  font-size: 1.5rem;
}
.add-location-modal-index-wrap .form-box {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.add-location-modal-index-wrap .form-box .select-available-days-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.add-location-modal-index-wrap .form-box .select-available-days-box .label {
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  color: #020202;
  text-transform: capitalize;
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days .item-box {
  width: -moz-max-content;
  width: max-content;
  border-radius: 3rem;
  padding: 0.6rem 2rem;
  background-color: #e8fff6;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days .item-box:hover {
  transform: translateY(-0.1rem);
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days .item-box .item {
  color: var(--primary-accent-color-two);
  font-size: 1.2rem;
  font-weight: 500;
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days .item-box-active {
  background-color: var(--primary-base-color);
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days .item-box-active:hover {
  transform: unset;
}
.add-location-modal-index-wrap .form-box .select-available-days-box .select-days .item-box-active .item {
  color: #ffffff;
}
.add-location-modal-index-wrap .form-box .two-group-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.settings-general-wrap-box-wrapper {
  flex: 1;
  display: flex;
  gap: 3rem;
}
.settings-general-wrap-box-wrapper .left-content {
  flex: 0 0 25rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.5rem;
}
.settings-general-wrap-box-wrapper .left-content .item-row {
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
}
.settings-general-wrap-box-wrapper .left-content .item-row::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.5rem;
  background-color: var(--primary-base-color);
  border-radius: 2rem;
  transform: translateY(1.5rem);
  visibility: hidden;
}
.settings-general-wrap-box-wrapper .left-content .item-row .name {
  transition: all 0.3s ease-in;
  text-wrap: nowrap;
}
.settings-general-wrap-box-wrapper .left-content .item-row:hover .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.settings-general-wrap-box-wrapper .left-content .item-row-active {
  background-color: var(--primary-accent-color-one);
  cursor: default;
}
.settings-general-wrap-box-wrapper .left-content .item-row-active .name {
  font-weight: 600;
  color: var(--primary-base-color);
}
.settings-general-wrap-box-wrapper .right-content {
  flex: 1;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}/*# sourceMappingURL=index.css.map */