.dispute-detail-modal-classname .raven-modal-content-wrap {
  width: 52rem !important;
}

.dispute-modal-content-wrap-index {
  display: flex;
  flex-direction: column;
}
.dispute-modal-content-wrap-index .title-select-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  justify-items: flex-start;
  margin-bottom: 2rem;
}
.dispute-modal-content-wrap-index .title-select-wrap .title-select {
  padding: 1.5rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.dispute-modal-content-wrap-index .title-select-wrap .title-select span {
  font-size: 1.25rem;
}
.dispute-modal-content-wrap-index .title-select-wrap .title-select-active {
  cursor: not-allowed;
}
.dispute-modal-content-wrap-index .title-select-wrap .title-select-active span {
  font-weight: 700;
  color: var(--primary-base-color);
}
.dispute-modal-content-wrap-index .content-to-show-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-height: 35rem;
  max-height: 55rem;
  overflow-y: scroll;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .empty_all_bb {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .empty_all_bb h6 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  color: var(--primary-base-color);
}
.dispute-modal-content-wrap-index .content-to-show-wrap .empty_all_bb p {
  color: var(--RAVEN-GREY, #676767);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.dispute-modal-content-wrap-index .content-to-show-wrap .form-content-wrap-index {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .form-content-wrap-index .badge-info-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .form-content-wrap-index .badge-info-box .bagde-box {
  padding: 0.8rem 1.5rem;
  border-radius: 2.5rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .form-content-wrap-index .badge-info-box .info-box {
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .form-content-wrap-index .badge-info-box .info-box .img-box {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .form-content-wrap-index .badge-info-box .info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .btn-box-wrap {
  margin-top: 2rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .value-label-wrap-index-box {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  padding: 2rem 0rem 2rem 0rem;
  gap: 2rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .value-label-wrap-index-box .label-value-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .value-label-wrap-index-box .label-value-box .label {
  font-weight: 600;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .value-label-wrap-index-box .label-value-box .value {
  text-align: right;
  flex: 0 0 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .value-label-wrap-index-box .label-value-box .copy-wrap-index {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.dispute-modal-content-wrap-index .content-to-show-wrap .value-label-wrap-index-box > *:not(:last-child) {
  padding-bottom: 2rem;
}/*# sourceMappingURL=DisputeDetailModal.css.map */