.cac-verification {
	width: 100%;
	display: flex;
	background-color: white;
	height: max-content;
	overflow: hidden;
	padding: 4.4rem 2.4rem;

	.cac-verification__navbar {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 45%;
		margin-right: 3.5rem;
		border-right: 0.7px solid #e5e6e6;
		.navbar__item {
			display: flex;
			padding: 1brem 0;
			align-items: center;
			gap: 0.8rem;
			cursor: pointer;

			&.active {
				border-right: 4px solid var(--primary-base-color);

				span {
					border: 1.6px solid var(--primary-base-color);
					p {
						font-weight: 600;
						color: #020202;
					}
				}

				p {
					font-weight: 600;
					color: #020202;
				}
			}

			&.complete {
				border-right: unset;
				display: flex;
				align-items: center;

				&::after {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' fill='%231ACE37'/%3E%3C/svg%3E");
					width: 1.6rem;
					height: 1.6rem;
					border-radius: 50%;
				}

				span {
					background: #e8fff6;
					border: 1.6px solid #e8fff6;
					p {
						font-weight: 400;
						color: #1ace37;
					}
				}

				p {
					font-weight: 400;
					color: #1ace37;
				}
			}

			span {
				display: grid;
				place-items: center;
				width: 3rem;
				height: 3rem;
				background: #f7f8f7;
				border-radius: 50%;

				p {
					font-weight: 400;
					font-size: 1.4rem;
					line-height: 140%;
					text-align: center;
					color: #676767;
				}
			}

			p {
				font-weight: 400;
				font-size: 1.2rem;
				line-height: 140%;
				display: flex;
				align-items: flex-end;
				color: #020202;
			}
		}
	}

	.cac-verification__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;

		&.active {
			// animation: animateUp 0.25s ease-in-out;
		}
		.content__title {
			display: flex;
			flex-direction: column;
			gap: 0.6rem;

			h5 {
				font-weight: 700;
				font-size: 2.4rem;
				display: flex;
				align-items: center;
				color: #1b1b1b;
			}

			p {
				font-weight: 400;
				font-size: 1.6rem;
				color: #333333;
			}
		}

		.content__country {
			display: flex;
			align-items: center;
			margin-top: 2.8rem;
			gap: 1.6rem;

			figure {
				display: flex;
				gap: 0.8rem;
				font-weight: 600;
				background: #f4f8fb;
				border-radius: 1.2rem;
				padding: 1rem 2rem;
			}

			p {
				font-weight: 400;
				font-size: 1.4rem;
				display: flex;
				align-items: center;
				gap: 1rem;
				color: #676767;

				b {
					display: flex;
					align-items: center;
					gap: 0.8rem;

					svg {
						display: grid;
						place-items: center;
					}
				}
			}
		}

		.content__business-category-select {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			margin-top: 2.8rem;
			width: 100%;

			.business-category-select__item {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				padding: 1.5rem 1.8rem;
				border-radius: 0.8rem;
				cursor: pointer;
				flex-direction: column;
				width: 100%;
				border: 1px solid var(--primary-grey-shade);
				gap: 0.7rem;

				figure {
					display: grid;
					place-items: center;
					width: 1.75rem;
					height: 1.75rem;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				.item__span {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;

					small {
						color: #676767;
						font-weight: 400;
						font-size: 1.2rem;
					}
				}

				.item__active {
					font-size: 1.4rem;
					font-weight: 600;
					color: #1ace37;
				}

				&.active {
					border: 1.6px solid var(--primary-base-color);
					background: #e9f5ff;
					p {
						font-weight: 600;
						font-size: 1.4rem;
						line-height: 140%;
						color: var(--primary-base-color);
					}
				}

				&:not(.active) {
					display: flex;
					gap: 0.7rem;
				}
			}

			.business-category-select__confirm-business {
				border: 1px dashed #e5e6e6;
			}

			p {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 140%;
				color: black;
			}

			.business-category-select__upload {
				display: flex;
				flex-direction: column;
				.upload__file-size {
					font-weight: 400;
					font-size: 1.2rem;
					margin-top: 1.2rem;
					color: #1b1b1b;
				}
			}
		}

		.content__business-category-button {
			margin-top: 4rem;
		}

		.content__upload-button {
			margin-top: 6.4rem;
		}

		.content__form-wrap {
			display: flex;
			width: 100%;
			gap: 2.8rem;
			flex-direction: column;
			margin-top: 4rem;

			.form__note {
				justify-content: center;
				align-items: center;
				padding: 0.8rem 1.2rem;
				width: max-content;
				background: #f0eeff;
				border-radius: 7.2rem;
				margin-top: 2rem;

				p {
					font-weight: 600;
					font-size: 1.2rems;
					line-height: 140%;
					text-align: center;
					color: #755ae2;
				}
			}

			.form__button {
				margin-top: 4.4rem;
			}
		}

		.content__not-your-business {
			display: flex;
			gap: 1rem;
			margin-top: 2.6rem;

			p {
				font-weight: 400;
				font-size: 1.6rem;
				display: flex;
				align-items: center;
				color: #333333;
			}

			span {
				font-weight: 600;
				font-size: 1.6rem;
				display: flex;
				align-items: center;
				color: var(--primary-base-color);
				display: flex;
				align-items: center;
				gap: 0.8rem;
				cursor: pointer;

				svg {
					transition: transform 300ms ease-in-out;
				}

				&:hover {
					svg {
						transform: translateX(3px);
					}
				}
			}
		}
	}
}

@keyframes animateUp {
	0% {
		transform: translate(-50%, -60%);
		opacity: 0.6;
	}

	100% {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}
