.Flier-two-terminal-index-wrap {
  margin-top: -1rem;
  min-height: 65rem;
  background-color: var(--primary-base-color);
  margin-bottom: 2.5rem;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.Flier-two-terminal-index-wrap > * {
  position: relative;
  z-index: 2;
}
.Flier-two-terminal-index-wrap::after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../../../../../assets/lier-bg-terminal.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.03;
}
.Flier-two-terminal-index-wrap .business-logo-box .img-box {
  width: 5rem;
  height: 5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Flier-two-terminal-index-wrap .business-logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
.Flier-two-terminal-index-wrap .business-logo-box .img-box-two {
  width: 12rem;
  height: 4rem;
}
.Flier-two-terminal-index-wrap .business-logo-box .img-box-two .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .bank-name {
  display: flex;
  flex-direction: column;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .bank-name .bank {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 450;
  margin-bottom: 0.3rem;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .bank-name .value {
  font-size: 1.75rem;
  font-weight: 700;
  color: #ffffff;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .mascot-account-number-box {
  width: 100%;
  position: relative;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .mascot-account-number-box > * {
  position: relative;
  z-index: 2;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .mascot-account-number-box::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 15rem;
  height: 15rem;
  z-index: 1;
  transform: translateY(-65%);
  background-image: url("../../../../../../assets/Easy_To_use_Merged 1flier-mascot-img (1).png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .mascot-account-number-box .account-detail {
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 1.2rem;
  background-color: rgb(26, 83, 85);
  filter: drop-shadow(0rem 0.4rem 6rem rgba(0, 0, 0, 0.3));
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .mascot-account-number-box .account-detail .account {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 450;
  margin-bottom: 0.3rem;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .mascot-account-number-box .account-detail .value {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 5rem;
  color: #ffffff;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .account-name-box {
  margin-top: 2rem;
}
.Flier-two-terminal-index-wrap .account-detail-box-wrap .account-name-box .value {
  color: #ffffff;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 2rem;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap .bank-name {
  display: flex;
  flex-direction: column;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap .bank-name .bank {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 450;
  margin-bottom: 0.3rem;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap .bank-name .value {
  font-size: 1.45rem;
  font-weight: 700;
  color: #ffffff;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap .qr-code-box {
  width: 10rem;
  height: 10rem;
  display: grid;
  place-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.8rem;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap .qr-code-box .qr {
  width: 80%;
  height: 80%;
}
.Flier-two-terminal-index-wrap .qr-code-text-box-wrap .qr-code-box .qr > * {
  width: 100%;
  height: 100%;
}
.Flier-two-terminal-index-wrap .underline {
  width: 100%;
  height: 0.05rem;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 2rem;
}
.Flier-two-terminal-index-wrap .we-support-box {
  width: -moz-max-content;
  width: max-content;
  align-self: center;
  margin-top: auto;
  display: flex;
  flex-direction: column;
}
.Flier-two-terminal-index-wrap .we-support-box .text {
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.Flier-two-terminal-index-wrap .we-support-box .support-img-box {
  margin-top: 1rem;
  width: 22rem;
  height: 3rem;
}
.Flier-two-terminal-index-wrap .we-support-box .support-img-box .img-box {
  width: 100%;
  height: 100%;
}
.Flier-two-terminal-index-wrap .we-support-box .support-img-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=FlierTwoIndex.css.map */