.settings-layout-index-wrap-box {
	// width: 100%;
	// height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	height: calc(100vh - 8rem);
	overflow-y: scroll;

	.settings__title {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.title__main {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;

			h5 {
				font-weight: 700;
				font-size: 2rem;
				line-height: 2.7rem;
				// color: #000000;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 1.6rem;
				color: #676767;
			}
		}

		.title__swap-btn {
			display: flex;
			align-items: center;
			padding: 1rem 2.3rem;
			gap: 1rem;
			cursor: pointer;
			background: #ffffff;
			box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
				0px 48px 64px -36px rgba(51, 51, 51, 0.08);
			border-radius: 10px;
			transition: transform 0.2s linear;

			&:hover {
				transform: translateY(-3px);
				figure {
					animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);

					@keyframes rotate {
						0% {
							transform: rotate(0deg);
						}
						50% {
							transform: rotate(100deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
				}
			}

			p {
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 140%;
				display: flex;
				align-items: center;
				color: var(--primary-base-color);
			}

			figure {
				display: grid;
				place-items: center;
				width: 2rem;
				height: 2rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.children-route-wrapper-box {
		display: flex;
		flex-direction: column;
		flex: 1;
		border-radius: 1.2rem;
		box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
		overflow-y: scroll;
		padding: 2rem;
		gap: 1.5rem;

		.route-path-wrap-box {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 2rem;
			padding-bottom: 2rem;
			border-width: 0.15rem;
			min-height: 7rem;
			// background-color: red;

			.right-box-btn {
				margin-left: auto;
				// width: 7rem;
				// background-color: red;
				// height: 4rem;
			}

			.item-row {
				padding: 1.2rem 1.6rem;
				border-radius: 0.8rem;
				position: relative;
				cursor: pointer;
				flex-wrap: wrap;
				&::after {
					content: '';
					position: absolute;
					top: 100%;
					left: 0%;
					width: 100%;
					height: 0.5rem;
					background-color: var(--primary-base-color);
					border-radius: 2rem;
					transform: translateY(1.5rem);
					visibility: hidden;
				}

				.name {
					transition: all 0.3s ease-in;
					text-wrap: nowrap;
				}

				&:hover {
					.name {
						font-weight: 600;
						color: var(--primary-base-color);
					}
				}
			}

			.item-row-active {
				background-color: var(--primary-accent-color-one);
				cursor: default;

				&::after {
					visibility: visible;
				}

				.name {
					font-weight: 600;
					color: var(--primary-base-color);
				}
			}
		}

		.children-wrap-box {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow-y: scroll;
			position: relative;
		}
	}
}
