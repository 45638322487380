.input_filter_export_general_wrap {
	display: flex;
	gap: 1rem;
}
.input_filter_export_general_wrap .pagination-wrap {
	width: 15rem;
	margin-top: 1.5rem;
}
.input_filter_export_general_wrap .pagination-wrap .top-bar__pagination {
	display: flex;
}

.fee-detail-dashboard-content-index-wrap .table__main .status_and_modal_holder {
	display: flex;
	padding-right: 2rem;
	align-items: center;
	justify-content: space-between;
}

.provider_image_and_name {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.provider_image_and_name .img-box {
	border-radius: 50%;
	width: 3.5rem;
	height: 3.5rem;
	overflow: hidden;
}
.provider_image_and_name .img-box .img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}
.provider_image_and_name .img-box-default {
	background-color: var(--primary-base-color);
	display: flex;
	align-items: center;
	justify-content: center;
}
.provider_image_and_name .img-box-default .img {
	width: 60%;
	height: 60%;
}
.provider_image_and_name .img-box-default .img path {
	fill: transparent !important;
	stroke: #ffffff !important;
} /*# sourceMappingURL=airtimeIndex.css.map */
