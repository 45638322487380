.user_terminal_details_general_container_holder_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  position: relative;
  padding: 2rem 0rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder {
  padding: 2rem;
  background-color: rgb(249, 249, 249);
  border-radius: 0.8rem;
  background-image: url("../../../../../../../../assets/halfbankboxs.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .header_content_holder_wrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .header_content_holder_wrap .terminal_hearder_name {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .header_content_holder_wrap .terminal_serial_number {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 1rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper .modal_header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper .modal_header_content .modal_header_content_users_assignee {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper .modal_header_content .modal_header_content_assignee {
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(100, 100, 100);
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper .modal_bottom_content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper .modal_bottom_content .modal_terminal_volumn {
  font-weight: 400;
  font-size: 1rem;
}
.user_terminal_details_general_container_holder_wrapper .user_terminal_details_general_container_holder .modal_content_holder_wrapper .modal_bottom_content .modal_terminal_volumn_content {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
}/*# sourceMappingURL=terminalDetailsIndex.css.map */