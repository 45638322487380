.cac-verification {
  width: 100%;
  display: flex;
  background-color: white;
  height: -moz-max-content;
  height: max-content;
  overflow: hidden;
  padding: 4.4rem 2.4rem;
}
.cac-verification .cac-verification__navbar {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 45%;
  margin-right: 3.5rem;
  border-right: 0.7px solid #e5e6e6;
}
.cac-verification .cac-verification__navbar .navbar__item {
  display: flex;
  padding: 1brem 0;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}
.cac-verification .cac-verification__navbar .navbar__item.active {
  border-right: 4px solid var(--primary-base-color);
}
.cac-verification .cac-verification__navbar .navbar__item.active span {
  border: 1.6px solid var(--primary-base-color);
}
.cac-verification .cac-verification__navbar .navbar__item.active span p {
  font-weight: 600;
  color: #020202;
}
.cac-verification .cac-verification__navbar .navbar__item.active p {
  font-weight: 600;
  color: #020202;
}
.cac-verification .cac-verification__navbar .navbar__item.complete {
  border-right: unset;
  display: flex;
  align-items: center;
}
.cac-verification .cac-verification__navbar .navbar__item.complete::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' fill='%231ACE37'/%3E%3C/svg%3E");
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
}
.cac-verification .cac-verification__navbar .navbar__item.complete span {
  background: #e8fff6;
  border: 1.6px solid #e8fff6;
}
.cac-verification .cac-verification__navbar .navbar__item.complete span p {
  font-weight: 400;
  color: #1ace37;
}
.cac-verification .cac-verification__navbar .navbar__item.complete p {
  font-weight: 400;
  color: #1ace37;
}
.cac-verification .cac-verification__navbar .navbar__item span {
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  background: #f7f8f7;
  border-radius: 50%;
}
.cac-verification .cac-verification__navbar .navbar__item span p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  text-align: center;
  color: #676767;
}
.cac-verification .cac-verification__navbar .navbar__item p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: flex-end;
  color: #020202;
}
.cac-verification .cac-verification__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.cac-verification .cac-verification__content .content__title {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.cac-verification .cac-verification__content .content__title h5 {
  font-weight: 700;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  color: #1b1b1b;
}
.cac-verification .cac-verification__content .content__title p {
  font-weight: 400;
  font-size: 1.6rem;
  color: #333333;
}
.cac-verification .cac-verification__content .content__country {
  display: flex;
  align-items: center;
  margin-top: 2.8rem;
  gap: 1.6rem;
}
.cac-verification .cac-verification__content .content__country figure {
  display: flex;
  gap: 0.8rem;
  font-weight: 600;
  background: #f4f8fb;
  border-radius: 1.2rem;
  padding: 1rem 2rem;
}
.cac-verification .cac-verification__content .content__country p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #676767;
}
.cac-verification .cac-verification__content .content__country p b {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.cac-verification .cac-verification__content .content__country p b svg {
  display: grid;
  place-items: center;
}
.cac-verification .cac-verification__content .content__business-category-select {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.8rem;
  width: 100%;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--primary-grey-shade);
  gap: 0.7rem;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item figure {
  display: grid;
  place-items: center;
  width: 1.75rem;
  height: 1.75rem;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item figure svg {
  width: 100%;
  height: 100%;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item .item__span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item .item__span small {
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item .item__active {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1ace37;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item.active {
  border: 1.6px solid var(--primary-base-color);
  background: #e9f5ff;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item.active p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--primary-base-color);
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__item:not(.active) {
  display: flex;
  gap: 0.7rem;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__confirm-business {
  border: 1px dashed #e5e6e6;
}
.cac-verification .cac-verification__content .content__business-category-select p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  color: black;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__upload {
  display: flex;
  flex-direction: column;
}
.cac-verification .cac-verification__content .content__business-category-select .business-category-select__upload .upload__file-size {
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  color: #1b1b1b;
}
.cac-verification .cac-verification__content .content__business-category-button {
  margin-top: 4rem;
}
.cac-verification .cac-verification__content .content__upload-button {
  margin-top: 6.4rem;
}
.cac-verification .cac-verification__content .content__form-wrap {
  display: flex;
  width: 100%;
  gap: 2.8rem;
  flex-direction: column;
  margin-top: 4rem;
}
.cac-verification .cac-verification__content .content__form-wrap .form__note {
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  width: -moz-max-content;
  width: max-content;
  background: #f0eeff;
  border-radius: 7.2rem;
  margin-top: 2rem;
}
.cac-verification .cac-verification__content .content__form-wrap .form__note p {
  font-weight: 600;
  font-size: 1.2rems;
  line-height: 140%;
  text-align: center;
  color: #755ae2;
}
.cac-verification .cac-verification__content .content__form-wrap .form__button {
  margin-top: 4.4rem;
}
.cac-verification .cac-verification__content .content__not-your-business {
  display: flex;
  gap: 1rem;
  margin-top: 2.6rem;
}
.cac-verification .cac-verification__content .content__not-your-business p {
  font-weight: 400;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #333333;
}
.cac-verification .cac-verification__content .content__not-your-business span {
  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: var(--primary-base-color);
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}
.cac-verification .cac-verification__content .content__not-your-business span svg {
  transition: transform 300ms ease-in-out;
}
.cac-verification .cac-verification__content .content__not-your-business span:hover svg {
  transform: translateX(3px);
}

@keyframes animateUp {
  0% {
    transform: translate(-50%, -60%);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}/*# sourceMappingURL=style.css.map */