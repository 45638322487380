.create-fee-category-modal-wrap-index .title-text-box {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.create-fee-category-modal-wrap-index .title-text-box .title {
  font-size: 2.2rem;
  font-weight: 700;
}
.create-fee-category-modal-wrap-index .title-text-box .text {
  font-size: 1.3rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 2.5rem;
  min-height: 35rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap .value-label-wrap-index-box {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap .value-label-wrap-index-box .label-value-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap .value-label-wrap-index-box .label-value-box .label {
  font-weight: 600;
}
.create-fee-category-modal-wrap-index .step-content-wrap .value-label-wrap-index-box .label-value-box .value {
  text-align: right;
  flex: 0 0 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
}
.create-fee-category-modal-wrap-index .step-content-wrap .value-label-wrap-index-box .label-value-box .copy-wrap-index {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap .value-label-wrap-index-box > *:not(:last-child) {
  padding-bottom: 2rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap .badge-info-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: -moz-max-content;
  width: max-content;
}
.create-fee-category-modal-wrap-index .step-content-wrap .badge-info-box .bagde-box {
  padding: 0.8rem 1.5rem;
  border-radius: 2.5rem;
}
.create-fee-category-modal-wrap-index .step-content-wrap .badge-info-box .info-box {
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.create-fee-category-modal-wrap-index .step-content-wrap .badge-info-box .info-box .img-box {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.create-fee-category-modal-wrap-index .step-content-wrap .badge-info-box .info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.create-fee-category-modal-wrap-index .step-content-wrap-no-height {
  min-height: unset;
}
.create-fee-category-modal-wrap-index .grey-step-wrap-box {
  display: flex;
  align-items: center;
  padding: 0.85rem 1.5rem;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  gap: 1rem;
}
.create-fee-category-modal-wrap-index .grey-step-wrap-box .step {
  min-height: 1.2rem;
  min-width: 2.5rem;
  background-color: #e3e3e3;
  border-radius: 4rem;
  cursor: pointer;
}
.create-fee-category-modal-wrap-index .grey-step-wrap-box .step-long {
  min-width: 5.5rem;
}
.create-fee-category-modal-wrap-index .grey-step-wrap-box .step-active {
  background-color: var(--primary-base-color);
}/*# sourceMappingURL=CreateFeeCategoryModal.css.map */